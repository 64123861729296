import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-card-gallery-dots-paginator',
  templateUrl: './card-gallery-dots-paginator.component.html',
  styleUrls: ['./card-gallery-dots-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class CardGalleryPaginatorComponent {
  protected data = [1];
  @Input() set totalPages(value: number) {
    this.data = new Array(value).fill(null).map((_, i) => i + 1);
  }
  @Input() page = 1;
  @Output() pageChange = new EventEmitter<number>();
}
