import { afterNextRender, Directive, EventEmitter, inject, NgZone, Output } from '@angular/core';
import { filter, fromEvent, map, takeUntil } from 'rxjs';

import { BaseTakeUntil } from '@core/helpers';
import { DOCUMENT } from '@angular/common';

@Directive({
  standalone: true,
  selector: '[globalKeydown]',
})
export class GlobalKeydownDirective extends BaseTakeUntil {
  @Output() save = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  private document = inject(DOCUMENT);

  constructor(private ngZone: NgZone) {
    super();
    afterNextRender(() => {
      if (!this.document) return;
      this.ngZone.runOutsideAngular(() => {
        fromEvent(this.document, 'keydown')
          .pipe(
            takeUntil(this.destroy$),
            filter(event => event instanceof KeyboardEvent),
            map(event => event as KeyboardEvent),
            filter(event => (event.metaKey || event.ctrlKey) && event.altKey)
          )
          .subscribe(event => {
            if (event.code === 'KeyS') this.ngZone.run(() => this.save.emit());
            else if (event.code === 'KeyW') this.ngZone.run(() => this.close.emit());
          });
      });
    });
  }
}
