import { ApplicationConfig, importProvidersFrom, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { provideRouter } from '@angular/router';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
// import { provideServiceWorker } from '@angular/service-worker';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { provideMarkdown } from 'ngx-markdown';
import { MetrikaModule } from '@modules/yandex-metrika';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { AppConstants } from '@core/app-constants';

import { provideAuth } from '@modules/auth';
import { provideSvgIcons } from '@modules/svg-icons';

import { routes } from './app.routes';

registerLocaleData(localeRu);

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    // provideServiceWorker('ngsw-worker.js', {
    //   enabled: false, //!isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideMarkdown(),
    importProvidersFrom([MatSnackBarModule, MetrikaModule.forRoot([{ id: AppConstants.YA_METRIKA_ID }])]),
    provideAuth(),
    provideSvgIcons(),
    provideCharts(withDefaultRegisterables()),
    { provide: LOCALE_ID, useValue: 'ru-RU' },
  ],
};
