import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PageComponentData } from '../pages';
import { CarouselComponent } from '../../components/carousel/carousel.component';
import { ScrollerComponent } from '../../components/scroller/scroller.component';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  host: { class: 'app-about-page app-about-page-fullheight' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CarouselComponent, ScrollerComponent],
})
export class PageComponent {
  @Input() data!: PageComponentData;
}
