import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { map } from 'rxjs';

import { ApiAchievement } from '@api/types';

import { AppService, SeoService, ToastrService } from '@core/services';
import { AppPluralizedIntNounState } from '@core/helpers';

import { ModalService } from '@modules/modal';
import { AuthService } from '@modules/auth';

import { AchievementCardComponent } from './achievement-card/achievement-card.component';
import { AchievementPreviewComponent } from './achievement-preview/achievement-preview.component';
import { NicknameComponent } from './nickname/nickname.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'app-page' },
  imports: [
    AsyncPipe,
    DecimalPipe,
    MatButtonModule,
    MatIcon,
    MatMenuModule,
    MatTooltipModule,
    AchievementPreviewComponent,
  ],
})
export class ProfileComponent {
  user$ = this.appService.user$;
  achievements$ = this.appService.achievements$;
  achievedLength$ = this.achievements$.pipe(map(data => data.filter(item => item.IsAchieved).length));

  pluralizedAchievement: AppPluralizedIntNounState = { zero: 'наград', one: 'награда', two: 'награды' };

  constructor(
    private seoService: SeoService,
    private appService: AppService,
    private authService: AuthService,
    private toastr: ToastrService,
    private modal: ModalService,
    private clipboard: Clipboard
  ) {}

  ngOnInit() {
    this.seoService.set({
      title: 'Личный кабинет - D&D Campaign',
      description: 'Личный кабинет - D&D Campaign. Сервис для игроков и мастеров Dungeons and Dragons.',
    });
  }

  open(data: ApiAchievement) {
    this.modal.open(AchievementCardComponent, { data, maxWidth: 600 });
  }

  changeNickname() {
    this.modal.open(NicknameComponent, { maxWidth: 400 });
  }

  logout() {
    this.authService.logout();
  }

  copy() {
    const nickname = this.appService.getUser()?.Nickname;
    const data = nickname || this.appService.getUser()?.Reference + '';
    if (data) {
      this.clipboard.copy(data);
      this.toastr.show(nickname ? 'Никнейм скопирован' : 'ID скопирован');
    }
  }
}
