import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SeoService } from '@core/services';

@Component({
  selector: 'app-license-agreement',
  templateUrl: './license-agreement.component.html',
  host: { class: 'app-page' },
  imports: [RouterModule],
})
export class LicenseAgreementComponent {
  constructor(private seoService: SeoService) {
    this.seoService.set({
      title: 'Пользовательское соглашение - D&D Campaign',
      description:
        'Настоящий документ «Пользовательское соглашение» представляет собой предложение авторов сайта заключить договор на изложенных ниже условиях Соглашения',
    });
  }
}
