import { Component } from '@angular/core';

import { SeoService } from '@core/services';
import { AppConstants } from '@core/app-constants';

@Component({
  standalone: true,
  selector: 'app-wizards-license-agreement',
  templateUrl: './wizards-license-agreement.component.html',
  host: { class: 'app-page' },
})
export class WizardsLicenseAgreementComponent {
  constructor(private seoService: SeoService) {
    this.seoService.set({
      title: 'WotC License - D&D Campaign',
      description: 'Описание лицензии Wizards of the Coast, используемоей сервисом D&D Campaign.',
    });
  }
}
