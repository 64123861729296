import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabNavPanel, MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { CharacterService } from '../character.service';

interface Tab {
  title: string;
  link: string;
}

@Component({
  selector: 'app-character-navbar',
  templateUrl: './character-navbar.component.html',
  styleUrls: ['./character-navbar.component.scss'],
  host: { class: 'app-bg-default' },
  imports: [AsyncPipe, RouterModule, MatProgressBarModule, MatTabsModule, MatButtonModule, MatIcon],
})
export class CharacterNavbarComponent {
  @Input() tabPanel?: MatTabNavPanel;
  data$ = this.characterService.data$;

  tabs: Tab[] = [
    { title: 'О пероснаже', link: 'general' },
    { title: 'Боевой Экран', link: 'combat' },
    { title: 'Социальный экран', link: 'social' },
    { title: 'Инвентарь', link: 'equipment' },
  ];

  activeTab = this.router.url.split('/')[4];

  constructor(private router: Router, private characterService: CharacterService) {}

  edit() {
    this.router.navigate(['characters', this.router.url.split('/')[3]]);
  }
}
