import { Routes } from '@angular/router';

import { AboutComponent } from './site/about/about.component';

export const routes: Routes = [
  { path: '', redirectTo: '/spells', pathMatch: 'full' },
  { path: 'login', redirectTo: '/spells?login=modal' },
  { path: 'register', redirectTo: '/spells?register=modal' },
  { path: 'about', component: AboutComponent },
  { path: 'admin', loadChildren: () => import('./site/admin/admin.routes') },
  { path: '', loadChildren: () => import('./site/pages/pages.routes') },
  { path: '**', redirectTo: '/not-found' },
];
