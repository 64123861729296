import { Component } from '@angular/core';

import { SeoService } from '@core/services';

@Component({
  standalone: true,
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  host: { class: 'app-page' },
})
export class CookieComponent {
  constructor(private seoService: SeoService) {
    this.seoService.set({
      title: 'Файлы cookie - D&D Campaign',
      description: 'Уведомление об использовании файлов куки (cookie)',
    });
  }
}
