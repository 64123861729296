<div class="app-achievement-preview-image-container">
  <img class="app-achievement-preview-image"
    draggable="false"
    [src]="imageSource">
</div>
<div class="app-achievement-preview-container mat-elevation-z1">
  <h3 class="app-text-uppercase app-text-center mb-2"
    [class]="data.IsAchieved ? 'app-text-accent' : 'app-text-secondary'"
    [innerHtml]="data.Name">
  </h3>
  <p class="app-opacity-2 app-text-center mb-0">{{data.ShortDescription}}</p>
  @if(data.ProgressPercentages) {
  <p class="app-opacity-2 app-text-center mb-0 mt-2">{{data.StatusDescription}}</p>
  <div class="app-achievement-preview-progress-bar"
    [style.width]="data.ProgressPercentages + '%'"></div>
  }
</div>