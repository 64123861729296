import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { MatFabButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

import { PlatformService, ScreenService } from '@core/services';

import { AuthService } from '@modules/auth';
import { ModalService } from '@modules/modal';

import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { AboutBannerComponent } from './components/about-banner/about-banner.component';
import { AppFooterComponent } from './components/footer/footer.component';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { RegisterModalComponent } from './components/register-modal/register-modal.component';
import { LoginModalComponent } from './components/login-modal/login-modal.component';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  host: { class: 'app-pages' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    RouterOutlet,
    MatFabButton,
    MatIcon,
    MatTooltip,
    ToolbarComponent,
    AboutBannerComponent,
    AppFooterComponent,
  ],
})
export class PagesComponent {
  protected isMobile$ = this.screen.isMobile$;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private screen: ScreenService,
    private modal: ModalService,
    private platform: PlatformService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParams['register']) {
      if (!this.authService.isAuthenticated() && this.platform.isBrowser)
        this.modal.open(RegisterModalComponent, { width: 'auto' });
      this.router.navigate([], { queryParams: { register: null }, queryParamsHandling: 'merge' });
    }
    if (this.route.snapshot.queryParams['login']) {
      if (!this.authService.isAuthenticated() && this.platform.isBrowser)
        this.modal.open(LoginModalComponent, { width: 'auto' });
      this.router.navigate([], { queryParams: { login: null }, queryParamsHandling: 'merge' });
    }
  }

  openFeedback() {
    this.modal.open(FeedbackModalComponent, { maxWidth: 430 });
  }
}
