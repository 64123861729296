import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { ScreenService } from '@core/services/screen.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  dialogRef?: MatDialogRef<any> | MatBottomSheetRef<any>;

  constructor(private dialog: MatDialog, private bottomSheet: MatBottomSheet, private screen: ScreenService) {}

  open(component: ComponentType<unknown>, config?: MatDialogConfig<unknown>): Observable<any> {
    this.close();
    if (this.screen.isMobile()) {
      this.dialogRef = this.bottomSheet.open(component, { panelClass: 'app-dialog', data: config?.data });
    } else {
      this.dialogRef = this.dialog.open(component, {
        width: config?.width || 'calc(100% - 32px)',
        maxWidth: config?.maxWidth || '1000px',
        maxHeight: config?.maxHeight || '80vh',
        hasBackdrop: config?.hasBackdrop || true,
        panelClass: config?.panelClass || 'app-dialog',
        backdropClass: config?.backdropClass || 'app-overlay-backdrop',
        autoFocus: config?.autoFocus || false,
        data: config?.data,
      });
    }
    return this.dialogRef instanceof MatDialogRef ? this.dialogRef.afterClosed() : this.dialogRef.afterDismissed();
  }

  close(data?: any) {
    if (this.dialogRef && this.dialogRef instanceof MatDialogRef) {
      this.dialogRef?.close(data);
      this.dialogRef = undefined;
    } else {
      this.bottomSheet?.dismiss(data);
    }
  }
}
