@if (data$ | async; as data) {
<div class="row">
  <div class="col-4">
    <h1 class="mb-2 d-flex align-items-center">
      {{data.Name}}
      <button mat-icon-button
        class="ms-auto"
        color="accent"
        (click)="edit()">
        <mat-icon>edit</mat-icon>
      </button>
    </h1>
    <div class="d-flex justify-content-between">
      <span class="mat-caption app-text-bold app-text-uppercase">{{data.Level}} Уровень</span>
      <span class="mat-caption app-text-bold app-text-uppercase app-text-secondary">{{data.Level + 1}} Уровень</span>
    </div>
    <mat-progress-bar mode="determinate"
      color="accent"
      class="mb-2"
      [value]="50">
    </mat-progress-bar>
  </div>
  <div class="col-8 d-flex align-items-center">
    <nav mat-tab-nav-bar
      [tabPanel]="tabPanel"
      mat-stretch-tabs="false"
      color="accent">
      @for (tab of tabs; track tab) {
      <a mat-tab-link
        [routerLink]="tab.link"
        [active]="activeTab === tab.link"
        (click)="activeTab = tab.link">
        {{tab.title}}
      </a>
      }
    </nav>
  </div>
</div>
}