<div class="container">
  <h1>Лицензия на использование контента</h1>

  <p>
    Мы самостоятельно переводим отдельные разделы SRD 5.1 от Wizards of the Coast LLC (см. <a
      routerLink="/docs/wizards-license">лицензию</a> от WotC LLS на перевод), чтобы иметь возможность
    предоставить вам информацию в максимально удобном виде. За новостями переводов вы можете следить на <a
      href="//boosty.to/dnd-campaign"
      target="_blank">Boosty</a> / <a href="//vk.com/dnd_campaign"
      target="_blank">ВК</a> / <a href="//t.me/dnd_campaign"
      target="_blank">Телеграм</a>.
  </p>
  <p class="mb-0">Перевод был осуществлен в несколько этапов:</p>
  <ul class="mt-1">
    <li>Машинный перевод: осуществлен с помощью сервисов <a href="https://www.smartcat.com/">smartcat.com</a> и <a
        href="https://www.deepl.com/">deepl.com</a></li>
    <li>
      Адаптация и вычитка DND Campaign team: Мерглодов Даниил, Ананьев Александр, Ананьева Дарья, Мерглодова Алина
    </li>
  </ul>

  <p>Вы можете использовать переведнный нами контент на условиях лицензии CC BY-NC-SA 4.0</p>

  <h2 class="mb-1">CC BY-NC-SA 4.0</h2>
  <p class="app-text-secondary">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 Международная</p>

  <p>
    Эта лицензия требует, чтобы при использовании контента в свои целях вы указывали указывали автора. Она позволяет
    распространять, изменять, адаптировать и дополнять материал на любом носителе или в любом формате только в
    некоммерческих целях. Если другие пользователи модифицируют или адаптируют ваш модифицированный материал, они должны
    лицензировать измененный материал на идентичных условиях.
  </p>

  <ul>
    <li>
      BY: Обязательно упомянание автораства, в нашем случае DND Campaign и ссылка на наш сайт
      <a href="https://dnd-campaign.ru/">dnd-campaign.ru</a>.
    </li>
    <li>
      NC: Разрешено только некоммерческое использование
      Некоммерческое означает не предназначенное в первую очередь для получения коммерческой выгоды или денежного
      вознаграждения.
    </li>
    <li>SA: Изменения должны распространяться на тех же условиях.</li>
  </ul>

  Пример указания авторства и лицензии:
  <code>
    <p xmlns:cc="http://creativecommons.org/ns#"
      xmlns:dct="http://purl.org/dc/terms/">
      <span property="dct:title">Перевод SRD 5.1</span> от <a property="cc:attributionName"
        rel="cc:attributionURL"
        href="http://dnd-campaign.ru/">DND Campaign</a> предоставляется по лицензии
      <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1"
        target="_blank"
        rel="license noopener noreferrer"
        style="display:inline-block;">CC BY-NC-SA 4.0<img
          style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
          alt=""><img style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"
          alt=""><img style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1"
          alt=""><img style="height:22px!important;margin-left:3px;vertical-align:text-bottom;"
          src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1"
          alt="">
      </a>
    </p>
  </code>
</div>