<app-card>
  <app-card-header>
    <app-card-title>Укажите Никнейм</app-card-title>
    <mat-icon app-card-close
      (click)="close()">
      close
    </mat-icon>
  </app-card-header>
  <p class="app-text-center mb-4">
    Укажите уникальный никнейм вместо номера, который вы будуте использовать для взаимодейсвия с другими игроками
    и по которому другие игроки будут взаимодействовать с вами.
  </p>
  <app-input placeholder="Никнейм"
    [control]="form.get('Nickname')">
    <mat-icon class="me-2 app-order-n1">alternate_email</mat-icon>
  </app-input>
  <app-card-footer>
    <button mat-button
      (click)="save()">
      Сохранить
    </button>
  </app-card-footer>
</app-card>