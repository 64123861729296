import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  TemplateRef,
} from '@angular/core';
import { AsyncPipe, DOCUMENT, NgTemplateOutlet } from '@angular/common';
import { BehaviorSubject, combineLatest, filter, fromEvent, map, takeUntil } from 'rxjs';

import { BaseTakeUntil } from '@core/helpers';

import { cardGelleyAnimation } from './card-gallery.animation';
import { CardGalleryPagesPaginatorComponent } from './card-gallery-pages-paginator/card-gallery-pages-paginator.component';
import { CardGalleryPaginatorComponent } from './card-gallery-dots-paginator/card-gallery-dots-paginator.component';
import { CardGalleryAnimatorDirective } from './card-gallery-animator.directive';

@Component({
  selector: 'app-card-gallery',
  templateUrl: './card-gallery.component.html',
  styleUrls: ['./card-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [cardGelleyAnimation],
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    CardGalleryPagesPaginatorComponent,
    CardGalleryPaginatorComponent,
    CardGalleryAnimatorDirective,
  ],
})
export class CardGalleryComponent extends BaseTakeUntil {
  private isScrollInside = false;
  private isScrollBusy = false;
  private scrollTimeout?: ReturnType<typeof setTimeout>;
  @HostListener('mouseover') mouseover = () => (this.isScrollInside = true);
  @HostListener('mouseout') mouseout = () => (this.isScrollInside = false);

  @Input() paginationType: 'pages' | 'dots' = 'pages';
  @Input() cardTemplate!: TemplateRef<any>;

  private dataSource$ = new BehaviorSubject<any[]>([]);
  data$ = this.dataSource$.asObservable();
  @Input() set data(value: any[]) {
    this.dataSource$.next(value);
  }

  private colsSource$ = new BehaviorSubject(1);
  cols$ = this.colsSource$.asObservable();
  @Input() @HostBinding('style.--card-gallery-grid-template-columns') set cols(value: number) {
    this.colsSource$.next(value);
  }
  get cols() {
    return this.colsSource$.getValue();
  }

  private rowsSource$ = new BehaviorSubject(1);
  rows$ = this.rowsSource$.asObservable();
  @Input() @HostBinding('style.--card-gallery-grid-template-rows') set rows(value: number) {
    this.rowsSource$.next(value);
  }
  get rows() {
    return this.rowsSource$.getValue();
  }

  private itemsPerPageSource$ = new BehaviorSubject(1);
  itemsPerPage$ = this.itemsPerPageSource$.asObservable();

  private totalPagesSource$ = new BehaviorSubject(1);
  totalPages$ = this.totalPagesSource$.asObservable();
  private setTotalPages(value: number) {
    this.totalPagesSource$.next(value);
  }
  private getTotalPages() {
    return this.totalPagesSource$.getValue();
  }

  private pageSource$ = new BehaviorSubject(1);
  page$ = this.pageSource$.asObservable();
  setPage(value: number) {
    if (value <= 0 || value > this.getTotalPages()) return;
    this.pageSource$.next(value);
  }
  private getPage() {
    return this.pageSource$.getValue();
  }

  private itemsSource$ = new BehaviorSubject<any[]>([]);
  items$ = this.itemsSource$.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {
    super();
    afterNextRender(() => this.handleScroll());
  }

  ngOnInit() {
    combineLatest([this.data$, this.cols$, this.rows$, this.page$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([data, cols, rows, page]) => {
        const itemsPerPage = cols * rows;
        this.itemsPerPageSource$.next(itemsPerPage);
        this.itemsSource$.next(data.slice((page - 1) * itemsPerPage, page * itemsPerPage));
      });

    combineLatest([this.data$, this.itemsPerPage$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([data, itemsPerPage]) => this.setTotalPages(Math.ceil(data.length / itemsPerPage)));
  }

  private handleScroll() {
    fromEvent(this.document.body, 'mousewheel')
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.isScrollInside),
        map(event => event as WheelEvent)
      )
      .subscribe(event => {
        if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
        this.scrollTimeout = setTimeout(() => (this.isScrollBusy = false), 100);
        if (this.isScrollBusy) return;
        this.isScrollBusy = true;
        if (event.deltaX > 0) this.nextPage();
        if (event.deltaX < 0) this.prevPage();
      });
  }

  override ngOnDestroy(): void {
    if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
    super.ngOnDestroy();
  }

  nextPage() {
    this.setPage(this.getPage() + 1);
  }

  prevPage() {
    this.setPage(this.getPage() - 1);
  }
}
