import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'app-card-gallery-pages-paginator',
  templateUrl: './card-gallery-pages-paginator.component.html',
  styleUrls: ['./card-gallery-pages-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatRippleModule],
})
export class CardGalleryPagesPaginatorComponent {
  @Input() totalPages: number = 1;
  @Input() page = 1;
  @Input() itemsPerPage = 1;
  @Input() totalItems = 0;
  @Output() pageChange = new EventEmitter<number>();

  get startIndexInPage() {
    return (this.page - 1) * this.itemsPerPage + 1;
  }

  get endIndexInPage() {
    const endIndex = this.page * this.itemsPerPage;
    return endIndex <= this.totalItems ? endIndex : this.totalItems;
  }

  get isFirstPage() {
    return this.page === 1;
  }

  get isLastPage() {
    return this.page === this.totalPages;
  }

  prevPage() {
    if (this.isFirstPage) return;
    this.pageChange.emit(this.page - 1);
  }

  nextPage() {
    if (this.isLastPage) return;
    this.pageChange.emit(this.page + 1);
  }
}
