import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import {
  CardComponent,
  CardHeaderComponent,
  CardTitleDirective,
  CardFooterDirective,
  CardCloseDirective,
} from '@modules/card';
import { ModalService } from '@modules/modal';

@Component({
  selector: 'app-feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
  imports: [
    MatButtonModule,
    MatIcon,
    CardComponent,
    CardHeaderComponent,
    CardTitleDirective,
    CardFooterDirective,
    CardCloseDirective,
  ],
})
export class FeedbackModalComponent {
  constructor(private modal: ModalService) {}

  close() {
    this.modal.close();
  }
}
