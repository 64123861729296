import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDivider } from '@angular/material/divider';

import { AppService } from '@core/services';
import { AppConstants } from '@core/app-constants';

import { CardComponent, CardHeaderComponent, CardTitleDirective } from '@modules/card';
import { ModalService } from '@modules/modal';

@Component({
  selector: 'app-footer-modal',
  templateUrl: './footer-modal.component.html',
  styleUrls: ['./footer-modal.component.scss'],
  imports: [AsyncPipe, RouterModule, MatDivider, CardComponent, CardHeaderComponent, CardTitleDirective],
})
export class FooterModalComponent {
  isDarkTheme$ = this.appService.isDarkTheme$;

  aboutLink = `//${AppConstants.URL}/about`;

  appVersion = AppConstants.RELEASE_VERSION;

  constructor(private appService: AppService, private modal: ModalService) {}

  close() {
    this.modal.close();
  }
}
