import {
  ApiCharacterAbilities,
  ApiCharacterGenderType,
  ApiCharacterRace,
  ApiCharacterWallet,
  ApiLookupSpell,
  ApiCharacterView,
  ApiCharacterViewClass,
} from '@api/types';
import {} from '@api/types/character-view';

import { ALIGNMENT_OPTIONS, GENDER_OPTIONS } from '@core/helpers';

export const isNotNullAndUndefined = (data: unknown | null | undefined) => data !== null && data !== undefined;

export const getBonusByLevel = (l?: number) => (!l || l < 1 ? 0 : l < 5 ? 2 : l < 9 ? 3 : l < 13 ? 5 : 6);

export const getAbilityMod = (data: number = 0) => Math.ceil(data - 10) / 2;

export const withPlusSign = (data: number | null | undefined) =>
  data !== null && data !== undefined && data >= 0 ? `+${data}` : data;

export class CharacterView implements ApiCharacterView {
  Id: Guid;
  Race: ApiCharacterRace | null;
  Spells: ApiLookupSpell[];
  Speed: number;
  Classes: ApiCharacterViewClass[];
  MaxHealthPoints: number | null;
  Abilities: ApiCharacterAbilities;
  Name: string;
  GenderType: ApiCharacterGenderType | null;
  AlignmentType: number | null;
  Age: number | null;
  Wallet: ApiCharacterWallet;
  PersonalStory: string | null;
  PersonalityTraits: string | null;
  Belief: string | null;
  Ideals: string | null;
  Bonds: string | null;
  Flaws: string | null;

  // Internal

  Level: number;
  Initiative: number | null = 0;
  LevelBonusModifier: number;
  PassivePerception: number = 0;
  GenderName: string;
  AlignmentName: string;
  ClassImage?: string;
  ClassNames: string;
  HitDices: string;

  constructor(data: ApiCharacterView) {
    this.Id = data.Id;
    this.Race = data.Race;
    this.Spells = data.Spells;
    this.Speed = data.Speed;
    this.Classes = data.Classes;
    this.MaxHealthPoints = data.MaxHealthPoints;
    this.Abilities = data.Abilities;
    this.Name = data.Name;
    this.GenderType = data.GenderType;
    this.AlignmentType = data.AlignmentType;
    this.Age = data.Age;
    this.Wallet = data.Wallet;
    this.PersonalStory = data.PersonalStory;
    this.PersonalityTraits = data.PersonalityTraits;
    this.Belief = data.Belief;
    this.Ideals = data.Ideals;
    this.Bonds = data.Bonds;
    this.Flaws = data.Flaws;

    this.Level = data.Classes.reduce((res, curr) => res + curr.Level, 0);
    // todo server should return final value
    // this.Initiative = !isNotNullAndUndefined(data?.Abilities.DexterityValue)
    //   ? null
    //   : getAbilityMod((data?.Abilities.DexterityValue || 0) + (data?.Abilities.DexterityRaceBonus || 0));
    this.LevelBonusModifier = getBonusByLevel(this?.Level);
    // todo server should return final value
    // this.PassivePerception = 10 + getAbilityMod(data?.Abilities.WisdomValue + data?.Abilities.WisdomCustomBonus);
    this.AlignmentName = ALIGNMENT_OPTIONS.find(item => item.Id === data.AlignmentType)?.Name || '';
    this.GenderName = GENDER_OPTIONS.find(item => item.Id === data.GenderType)?.Name || '';
    this.ClassImage = data.Classes[0]?.ImageId;
    this.ClassNames = data.Classes.map(item => item.Name).join(', ');
    this.HitDices = data.Classes.map(item => `${item.Level}dX`).join(' + ');
  }
}
