import { Component, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { ApiService } from '@api/services/api.service';
import { ApiHttpErrorResponse } from '@api/types';

import { ToastrService } from '@core/services/toastr.service';
import { AppConstants } from '@core/app-constants';

import { AuthService } from '@modules/auth';
import { InputComponent } from '@modules/input';
import { ModalService } from '@modules/modal';

import { RegisterModalComponent } from '../register-modal/register-modal.component';
import { LoginForm } from './login-modal.form';

export interface LoginModalData {
  successRoute?: string;
}

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  host: { class: 'app-auth-modal' },
  imports: [MatButtonModule, ReactiveFormsModule, MatIconModule, InputComponent],
})
export class LoginModalComponent {
  form = new LoginForm();

  protected passwordHidden = true;

  protected registerHidden = true;

  dev = AppConstants.DEV;

  private data?: LoginModalData;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) matDialogData: LoginModalData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) matBottomSheetData: LoginModalData,
    private router: Router,
    private toastr: ToastrService,
    private apiService: ApiService,
    private authService: AuthService,
    private modal: ModalService
  ) {
    if (matDialogData) this.data = matDialogData;
    if (matBottomSheetData) this.data = matBottomSheetData;
  }

  login() {
    if (!this.form.validate()) return;
    this.apiService.login(this.form.getRawValue()).subscribe({
      next: data => {
        this.modal.close();
        this.authService.authenticate(data);
        if (this.data?.successRoute) this.router.navigate([this.data.successRoute]);
        else if (this.authService.getRole() === 'Admin') this.router.navigate(['/admin']);
      },
      error: (error: ApiHttpErrorResponse) => {
        this.form.errorHandler(error);
        if (error.error.Value.Messages) {
          this.toastr.show(error.error.Value.Messages.join(' '), { duration: 5000 });
          this.registerHidden = false;
        }
      },
    });
  }

  register() {
    this.modal.close();
    this.modal.open(RegisterModalComponent, { width: 'auto', data: this.data });
  }
}
