import { afterNextRender, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, fromEvent } from 'rxjs';

import { Metrika } from '@modules/yandex-metrika';

import { ApiService } from '@api/services';
import { ApiAchievement, ApiUser } from '@api/types';

import { LookupDataService } from './lookup-data.service';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private userSource$ = new BehaviorSubject<ApiUser | null>(null);
  user$ = this.userSource$.asObservable();
  setUser(value: ApiUser | null) {
    this.userSource$.next(value);
    this.metrika.setUserID(value?.Id || '');
    if (!value) this.setAchievements([]);
    else this.apiService.getAchievements().subscribe(data => this.setAchievements(data));
  }
  getUser() {
    return this.userSource$.getValue();
  }

  private achievementsSource$ = new BehaviorSubject<ApiAchievement[]>([]);
  achievements$ = this.achievementsSource$.asObservable();
  private setAchievements(value: ApiAchievement[]) {
    this.achievementsSource$.next(value);
  }

  private isDarkThemeSource$ = new BehaviorSubject<boolean>(false);
  isDarkTheme$ = this.isDarkThemeSource$.asObservable();
  setIsDarkTheme(value: boolean) {
    this.isDarkThemeSource$.next(value);
    this.cookie.setItem('dnd_campaign_theme', value ? 'dark' : 'light', 365);
  }
  getIsDarkTheme() {
    return this.isDarkThemeSource$.getValue();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private lookupDataService: LookupDataService,
    private cookie: CookieService,
    private metrika: Metrika,
    private apiService: ApiService
  ) {
    // Required to restore current theme on Browser after hydration
    afterNextRender(() => this.syncTheme());
  }

  private syncTheme() {
    const window = this.document.defaultView;
    if (!window) return;
    const mediaQuery = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
    const currentCookieTheme = this.cookie.getItem('dnd_campaign_theme');
    if (currentCookieTheme) this.setIsDarkTheme(currentCookieTheme === 'dark');
    else this.setIsDarkTheme(mediaQuery.matches ?? false);
    fromEvent<MediaQueryListEvent>(mediaQuery, 'change').subscribe(data => this.setIsDarkTheme(data.matches));
  }

  loadData() {
    this.lookupDataService.loadData();
  }
}
