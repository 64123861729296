import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject, map, takeUntil } from 'rxjs';

import { ApiService } from '@api/services';
import { ApiCampaignListItem } from '@api/types';

import { AppService, ScreenService } from '@core/services';
import { BaseTakeUntil } from '@core/helpers';

import { CardGalleryComponent } from '@modules/card-gallery';

import { CampaignCardComponent } from './campaign-card/campaign-card.component';

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss'],
  imports: [CardGalleryComponent, CampaignCardComponent, AsyncPipe],
})
export class CampaignListComponent extends BaseTakeUntil {
  private campaignsSource$ = new BehaviorSubject<ApiCampaignListItem[]>([]);
  campaigns$ = this.campaignsSource$.asObservable();
  private setCampaigns(value: ApiCampaignListItem[]) {
    this.campaignsSource$.next(value);
  }

  cols$ = this.screen.isMobile$.pipe(map(data => (data ? 1 : 2)));

  constructor(
    private router: Router,
    private apiService: ApiService,
    private appService: AppService,
    private screen: ScreenService
  ) {
    super();
  }

  ngOnInit() {
    this.appService.user$.pipe(takeUntil(this.destroy$)).subscribe(() => this.loadCampaigns());
  }

  private loadCampaigns() {
    if (this.appService.getUser())
      this.apiService
        .getCampaigns()
        .subscribe({ next: data => this.setCampaigns(data), error: () => this.setCampaigns([]) });
    else this.setCampaigns([]);
  }

  openCampaign(data?: ApiCampaignListItem) {
    this.router.navigate(['campaigns', data?.Id || 'add']);
  }
}
