<div class="row">
  <div class="col-12 col-lg-3 col-md-6">
    @if(user$ | async; as user) {
    <div class="app-profile-card mb-3 mat-elevation-z1">
      <div class="app-profile-card-image-container">
        <div class="app-bg-hexagon app-bg-accent app-profile-card-image-hexagon">
          <img class="app-profile-card-image"
            src="/assets/logo-ios.png">
        </div>
      </div>
      <button mat-icon-button
        class="app-profile-card-more"
        [matMenuTriggerFor]="profileMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #profileMenu="matMenu">
        <button mat-menu-item
          disableRipple
          (click)="copy()">
          Скопировать {{user.Nickname ? 'Никнейм': 'ID'}}
        </button>
        <button mat-menu-item
          disableRipple
          [disabled]="!user.IsNicknameAvailable"
          (click)="changeNickname()">
          Редактировать Никнейм
        </button>
      </mat-menu>
      <div>
        @if (user.Nickname) {
        <h1 class="app-text-accent app-text-uppercase app-text-center mb-0 app-pointer"
          (click)="copy()">&commat;{{user.Nickname}}</h1>
        <p class="app-opacity-2 app-text-bold app-text-center mb-0">{{user.Reference | number: '1.0-0'}}</p>
        }
        @else {
        <h1 class="app-text-uppercase app-text-center mb-0 app-pointer"
          (click)="copy()">{{user.Reference | number: '1.0-0'}}</h1>
        }
        <p class="app-opacity-2 app-text-center">{{(user$ | async)?.Email}}</p>
        <p class="app-text-center">
          Это ваш <span class="app-text-accent">{{user.Nickname ? 'никнейм': 'ID'}}</span>. Используйте его,
          чтобы взаимодействовать с вашим мастером или игроками.
        </p>
        <div class="d-flex justify-content-center">
          <button mat-button
            color="accent"
            (click)="logout()">
            Выйти
          </button>
        </div>
      </div>
    </div>
    <p class="app-text-secondary">
      Часть достижений обновляется раз в месяц. Если вы обнаружили, что не получили какое-то из достижений, пожалуйста
      свяжитесь с нами в
      <a href="//vk.com/dnd_campaign"
        target="_blank">ВК</a>.
    </p>
    }
  </div>
  <div class="col">
    <h1 class="app-text-uppercase mb-2">Достижения и награды</h1>
    <p class="app-opacity-2 mb-3">
      Некоторые достижения лимитированны и могут быть получены эксклюзивно вами.
    </p>
    <h3 class="app-text-uppercase">
      Эксклювные достижения DND Campaign
      <span class="app-text-secondary"> Получено {{achievedLength$ | async}} из {{(achievements$ |
        async)?.length}}</span>
    </h3>
    <div class="app-profile-achievements">
      @for (item of (achievements$ | async); track item.Id) {
      <app-achievement-preview [data]="item"
        (click)="open(item)">
      </app-achievement-preview>
      }
    </div>
  </div>
</div>