import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';
import { PlatformService } from '@core/services';

export type AppScrollPosition = [number, number] | undefined;

@Directive({
  standalone: true,
  selector: '[position]',
})
export class RestorePositionDirective implements AfterViewInit, OnDestroy {
  @Input('position') position?: AppScrollPosition;
  @Output('positionChange') positionChange = new EventEmitter<AppScrollPosition>();

  constructor(private host: ElementRef<HTMLElement>, private ngZone: NgZone, private platform: PlatformService) {}

  ngAfterViewInit(): void {
    if (this.platform.isBrowser && this.host.nativeElement.scroll)
      this.ngZone.runOutsideAngular(() => this.position && this.host.nativeElement.scroll(...this.position));
  }

  ngOnDestroy(): void {
    this.positionChange.emit([this.host?.nativeElement.scrollLeft ?? 0, this.host?.nativeElement.scrollTop ?? 0]);
  }
}
