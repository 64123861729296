import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ApiAchievement } from '@api/types';

import { getAchievementImage } from '../achievement';

@Component({
  selector: 'app-achievement-preview',
  templateUrl: './achievement-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'app-achievement-preview' },
  imports: [],
})
export class AchievementPreviewComponent {
  @Input() data!: ApiAchievement;

  imageSource?: string;

  ngOnInit() {
    this.imageSource = getAchievementImage(this.data.Type, !!this.data.IsAchieved);
  }
}
