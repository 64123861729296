<app-card>
  <app-card-header>
    <app-card-title>Обратная связь</app-card-title>
    <mat-icon app-card-close
      (click)="close()">
      close
    </mat-icon>
  </app-card-header>
  <p class="mb-0">
    Если вы нашли ошибку, у вас есть предложения, вопросы, или вы хотите сообщить, что мы классные 😎, пожалуйста
    сделайте это через сообщения в группе ВК.
  </p>
  <app-card-footer>
    <a mat-raised-button
      class="app-button-vk"
      href="https://vk.com/dnd_campaign"
      target="_blank">
      Написать в ВК
    </a>
  </app-card-footer>
</app-card>