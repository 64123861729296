import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
  animations: [
    trigger('carouselImageTrigger', [
      transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('300ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class CarouselComponent implements OnInit {
  @Input() images: { url: string; title: string }[] = [];

  protected index = 0;

  protected image?: { url: string; title: string };

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateImage();
  }

  protected updateImage() {
    this.image = undefined;
    this.cdr.detectChanges();
    this.image = this.images[this.index];
    this.cdr.detectChanges();
  }

  protected prevImg() {
    this.decreaseIndex();
    this.updateImage();
  }

  protected nextImg() {
    this.increaseIndex();
    this.updateImage();
  }

  private decreaseIndex() {
    this.index = this.index <= 0 ? this.images.length - 1 : this.index - 1;
  }

  private increaseIndex() {
    this.index = this.index >= this.images.length - 1 ? 0 : this.index + 1;
  }
}
