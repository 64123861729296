import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { ApiService } from '@api/services';
import { ApiHttpErrorResponse } from '@api/types';

import { AppService, ToastrService } from '@core/services';

import { ModalService } from '@modules/modal';
import { InputComponent } from '@modules/input';
import {
  CardComponent,
  CardHeaderComponent,
  CardTitleDirective,
  CardFooterDirective,
  CardCloseDirective,
} from '@modules/card';

import { ProfileForm } from './profile.form';

@Component({
  selector: 'app-nickname',
  templateUrl: './nickname.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatButton,
    MatIcon,
    CardComponent,
    CardHeaderComponent,
    CardTitleDirective,
    CardFooterDirective,
    CardCloseDirective,
    InputComponent,
  ],
})
export class NicknameComponent {
  form = new ProfileForm();

  constructor(
    private modal: ModalService,
    private appService: AppService,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const user = this.appService.getUser();
    this.form.setValue({ Nickname: user?.Nickname || null });
  }

  save() {
    if (this.form.pristine) this.close();
    else {
      this.apiService.updateCurrentUser({ Nickname: this.form.getRawValue().Nickname || null }).subscribe({
        next: data => {
          this.appService.setUser(data);
          this.toastr.show(data.Nickname ? `Поздравляем, теперь вы ${data.Nickname}!` : 'Никнейм сброшен.');
          this.close();
        },
        error: (error: ApiHttpErrorResponse) =>
          this.toastr.show(
            error.error?.Value?.Messages?.join(' ') ?? 'Что-то пошло не так, попробуйте еще раз или напишите нам.'
          ),
      });
    }
  }

  close() {
    this.modal.close();
  }
}
