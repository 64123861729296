<div class="app-achievement-card-image-container mb-3">
  <img class="app-achievement-card-image"
    [class.app-achievement-card-image-achieved]="data.IsAchieved"
    draggable="false"
    [src]="imageSource">
</div>
<div class="app-achievement-card-actions">
  <button mat-icon-button
    (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<h1 class="mat-headline-4 app-text-uppercase app-text-center mb-1"
  [class.app-text-secondary]="!data.IsAchieved"
  [innerHtml]="data.Name">
</h1>
<p class="app-opacity-2 app-text-center">{{data.ShortDescription}}</p>
@if (data.IsLimited) {
<div class="d-flex justify-content-center mb-2">
  <span class="app-highlighted-span mx-1 app-achievement-badge">Лимитированное</span>
  @if (data.Number) {
  <span class="app-highlighted-span mx-1 app-achievement-badge">Экземпляр
    #{{data.Number}}</span>
  }
</div>
}
<app-markdown class="app-text-center"
  [data]="data.FullDescription">
</app-markdown>
@if(data.ProgressPercentages) {
<div class="d-flex mb-2 mt-3">
  <p class="mb-0 me-auto">{{data.StatusDescription}}</p>
  <span class="ms-2">{{data.ProgressPercentages}}%</span>
</div>
<div class="app-achievement-card-progress-bar">
  <div class="app-achievement-card-progress-bar-fill"
    [style.width]="data.ProgressPercentages + '%'">
  </div>
</div>
}
@else if(data.StatusDescription) {
<p class="app-opacity-2 app-text-right mb-0">{{data.StatusDescription}}</p>
}