<div matRipple
  [matRippleDisabled]="isFirstPage"
  class="pagination-button pagination-back"
  [ngClass]="{'app-opacity-3': isFirstPage, 'app-pointer': !isFirstPage}"
  (click)="prevPage()">
  <img src="/assets/interface-icons/pagination-arrow.svg"
    alt="Предыдущая группа"
    draggable="false">
</div>
<div class="pagination-pages">{{startIndexInPage}} - {{endIndexInPage}} / {{totalItems}}</div>
<div matRipple
  [matRippleDisabled]="isLastPage"
  class="pagination-button"
  [ngClass]="{'app-opacity-3': isLastPage, 'app-pointer': !isLastPage}"
  (click)="nextPage()">
  <img src="/assets/interface-icons/pagination-arrow.svg"
    alt="Следующая группа"
    draggable="false">
</div>