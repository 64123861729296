<app-card-gallery [data]="(campaigns$ | async)!"
  [cardTemplate]="cardTemplate"
  [cols]="(cols$ | async)!"
  [rows]="(campaigns$ | async)!.length > (cols$ | async)! ? 2 : 1">
  <ng-template #cardTemplate
    let-item="item">
    <app-campaign-card [data]="item"
      (click)="openCampaign(item)">
    </app-campaign-card>
  </ng-template>
</app-card-gallery>