import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

import { VideoComponent } from '../../components/video/video.component';
import { ScrollerComponent } from '../../components/scroller/scroller.component';

@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrls: ['./greetings.component.scss'],
  host: { class: 'app-about-page app-about-page-fullheight' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [VideoComponent, ScrollerComponent],
})
export class GreetingsComponent {
  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;

  isPlaying = false;

  play() {
    this.video.nativeElement.play().then(() => (this.isPlaying = true));
    this.isPlaying = true;
  }

  pause() {
    this.video.nativeElement.pause();
    this.isPlaying = false;
  }
}
