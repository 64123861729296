import { Route } from '@angular/router';

import { canActivateAdmin } from '../admin/admin.guard';
import { PagesComponent } from './pages.component';
import { CharacterViewComponent } from './characters/character-view/character-view.component';
import { ContentLicenseComponent } from './docs/content-license/content-license.component';
import { CookieComponent } from './docs/cookie/cookie.component';
import { LicenseAgreementComponent } from './docs/license-agreement/license-agreement.component';
import { WizardsLicenseAgreementComponent } from './docs/wizard-license-agreement/wizards-license-agreement.component';
import { EmptyComponent } from './empty/empty.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { isAuthenticated } from './pages.guard';
import { ProfileComponent } from './profile/profile.component';

export default [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'characters/view/:id', pathMatch: 'full', redirectTo: 'characters/view/:id/general' },
      {
        path: 'characters/view/:id',
        component: CharacterViewComponent,
        loadChildren: () => import('./characters/character-view/character-view.routes'),
        canActivate: [isAuthenticated],
      },
      {
        path: 'characters',
        loadChildren: () => import('./characters/character-details/character-details.routes'),
        canActivate: [isAuthenticated],
      },
      {
        path: 'campaigns',
        loadChildren: () => import('./campaigns/campaign-details/campaign-details.routes'),
        canActivate: [canActivateAdmin],
      },
      { path: 'spells', loadChildren: () => import('./spells/spells.routes') },
      { path: 'classes', loadChildren: () => import('./classes/classes.routes') },
      { path: 'races', loadChildren: () => import('./races/races.routes') },
      {
        path: 'magic-items',
        loadChildren: () => import('./magic-items/magic-items.routes'),
        canActivate: [canActivateAdmin],
      },
      { path: 'profile', component: ProfileComponent, canActivate: [isAuthenticated] },
      { path: 'docs/cookie', component: CookieComponent },
      { path: 'docs/license', component: LicenseAgreementComponent },
      { path: 'docs/content-license', component: ContentLicenseComponent },
      { path: 'docs/wizards-license', component: WizardsLicenseAgreementComponent },
      { path: 'not-authorized', component: EmptyComponent },
      { path: 'not-found', component: NotFoundComponent },
    ],
  },
] satisfies Route[];
