import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { ApiAchievement } from '@api/types';

import { ModalService } from '@modules/modal';
import { MarkdownComponent } from '@modules/markdown';

import { getAchievementImage } from '../achievement';

@Component({
  selector: 'app-achievement-card',
  templateUrl: './achievement-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'app-achievement-card app-no-bottom-sheet-border-bottom-radius' },
  imports: [MatIconButton, MatIcon, MarkdownComponent],
})
export class AchievementCardComponent {
  protected data!: ApiAchievement;
  protected imageSource?: string;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) matDialogData: ApiAchievement,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) matBottomSheetData: ApiAchievement,
    private modal: ModalService
  ) {
    if (matDialogData) this.data = matDialogData;
    if (matBottomSheetData) this.data = matBottomSheetData;
  }

  ngOnInit() {
    this.imageSource = getAchievementImage(this.data.Type, !!this.data.IsAchieved);
  }

  close() {
    this.modal.close();
  }
}
