<div class="row">
  <div class="col-12 col-lg-4 mb-3">
    <h1>Мои Кампании</h1>
    <p class="d-none d-lg-block app-text-secondary">
      Создайте ваши кампании и управляйте ими. Добавьте заметки, планы встреч, персонажей, монстров. Записывайте
      важные события и слухи, которые могут оказать какое-то влияние на сюжет или будут использованы позднее. А также
      многое и многое другое...
    </p>
    <button mat-flat-button
      disabled
      color="accent"
      class="app-big-button app-placeholder"
      (click)="addCampaign()">
      <mat-icon>add_circle_outline</mat-icon>
      Добавить Кампанию
    </button>
  </div>
  <div class="col-12 col-lg-8 mb-3">
    <app-campaign-list></app-campaign-list>
  </div>
</div>
<div class="row mt-3 mt-lg-0">
  <div class="col-12 col-lg-4 mb-3">
    <h1>Мои Персонажи</h1>
    <p class="d-none d-lg-block app-text-secondary">
      Создайте персонажей, проработайте их характеристики, отслеживайте развитие, предметы, деньги и другие аспекты,
      важные для кампании.
    </p>
    <button mat-flat-button
      color="accent"
      class="app-big-button"
      (click)="addCharacter()">
      <mat-icon>add_circle_outline</mat-icon>
      Добавить Персонажа
    </button>
  </div>
  <div class="col-12 col-lg-8 mb-3">
    <app-character-list></app-character-list>
  </div>
</div>