import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { PlatformService } from '@core/services';

@Component({
  standalone: true,
  selector: 'app-empty',
  template: '',
  host: { class: 'app-page' },
})
export class EmptyComponent {
  constructor(private platform: PlatformService, private router: Router) {}

  ngOnInit() {
    // This is empty component is a placeholder for SSR rendering pages which require Auth
    // Not authenticaed users yet are redirected here instead of default spells page on SSR to reduce amount of pregenerated data
    // In case user somehow entered empty page redirect him back to home.
    if (this.platform.isBrowser) this.router.navigate(['/']);
  }
}
