import { Pipe, PipeTransform } from '@angular/core';

import { ApiCharacterListItemClass } from '@api/types';

@Pipe({
  name: 'characterCardClasses',
  standalone: true,
})
export class CharacterCardClassesPipe implements PipeTransform {
  transform(data: ApiCharacterListItemClass[]): string {
    return data.map(item => `${item.Name} (${item.Level})`).join(' / ');
  }
}
