@if(!aboutBannerHidden) {
<app-card class="mb-3"
  backgroundImage="assets/illustrations/about-banner.webp"
  contentClass="mt-0">
  <app-card-header>
    <app-card-title class="app-text-accent">
      Подробнее о DND Campaign
    </app-card-title>
    <button mat-icon-button
      class="ms-auto"
      (click)="close($event)">
      <mat-icon>close</mat-icon>
    </button>
  </app-card-header>
  <p class="app-text-secondary">
    Мы только в начале пути, здесь ты сможешь узнать о наших грандиозных планах и заглянуть за ширму будущих обновлений.
  </p>
  <a mat-raised-button
    color="accent"
    [href]="aboutLink">
    Узнать подробнее
  </a>
</app-card>
}