import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ApiService } from '@api/services';
import { ApiCharacterView } from '@api/types/character-view';

import { CharacterView } from './character';

@Injectable({
  providedIn: 'root',
})
export class CharacterService {
  private dataSource$ = new BehaviorSubject<CharacterView | null>(null);
  data$ = this.dataSource$.asObservable();
  setCharacter(value: ApiCharacterView | null) {
    this.dataSource$.next(value ? new CharacterView(value) : null);
  }

  constructor(private apiService: ApiService) {}

  loadCharacter(id: string | null) {
    if (id) this.apiService.getCharacterView(id).subscribe(data => this.setCharacter(data));
    else this.setCharacter(null);
  }
}
