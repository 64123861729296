@if((isMobile$ | async) || isPlatformServer) {
<mat-toolbar class="d-lg-none mat-elevation-z3 p-0">
  <div class="d-flex align-items-center app-w-100 justify-content-between">
    @if(!prod) {DEV}
    <img class="ms-2 logo-img"
      src="/assets/logo.webp"
      alt="D&D Campaign"
      routerLink="/home">
    <a mat-icon-button
      class="app-toolbar-button"
      disableRipple
      routerLink="/home"
      routerLinkActive="active">
      <mat-icon svgIcon="home"></mat-icon>
    </a>
    <a mat-icon-button
      class="app-toolbar-button"
      disableRipple
      routerLink="/spells"
      routerLinkActive="active">
      <mat-icon svgIcon="spell"></mat-icon>
    </a>
    <a mat-icon-button
      class="app-toolbar-button"
      disableRipple
      routerLink="/classes"
      routerLinkActive="active">
      <mat-icon svgIcon="barbarian"></mat-icon>
    </a>
    <a mat-icon-button
      class="app-toolbar-button"
      disableRipple
      routerLink="/races"
      routerLinkActive="active">
      <mat-icon svgIcon="wisdom"></mat-icon>
    </a>
    <a mat-icon-button
      class="app-toolbar-button p-0"
      color="accent"
      href="//boosty.to/dnd-campaign"
      target="_blank">
      <mat-icon class="app-icon-lg me-0"
        svgIcon="boosty-color">
      </mat-icon>
    </a>
    <button mat-icon-button
      [matMenuTriggerFor]="extraLinks"
      class="me-2 app-toolbar-button">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
  <mat-menu #extraLinks="matMenu">
    @if(navigationDisabled$ | async) {
    <button mat-menu-item
      disableRipple
      class="navigation-disabled"
      (click)="showBestiaryMessage()">
      <mat-icon svgIcon="bestiary"
        class="me-1">
      </mat-icon>
      Бестиарий
    </button>
    <button mat-menu-item
      disableRipple
      class="navigation-disabled"
      (click)="showMessage()">
      <mat-icon svgIcon="items"
        class="me-1">
      </mat-icon>
      Предметы
    </button>
    }
    @else {
    <button mat-menu-item
      routerLink="/monsters"
      routerLinkActive="active">
      <mat-icon svgIcon="bestiary"
        class="me-1">
      </mat-icon>
      Бестиарий
    </button>
    <button mat-menu-item
      routerLink="/magic-items"
      routerLinkActive="active">
      <mat-icon svgIcon="items"
        class="me-1">
      </mat-icon>
      Предметы
    </button>
    }
    @if(isDarkTheme$ | async) {
    <button mat-menu-item
      (click)="changeTheme()">
      <mat-icon class="me-1">light_mode</mat-icon>
      Светлая тема
    </button>
    } @else {
    <button mat-menu-item
      (click)="changeTheme()">
      <mat-icon class="me-1">dark_mode</mat-icon>
      Темная тема
    </button>
    }
    @if(user$ | async) {
    <a mat-menu-item
      routerLink="/profile"
      routerLinkActive="active">
      <mat-icon>person</mat-icon>
      Личный кабинет
    </a>
    } @else {
    <button mat-menu-item
      (click)="login()">
      <mat-icon>person</mat-icon>
      Войти
    </button>
    }
    <button mat-menu-item
      (click)="links()">
      <mat-icon>dataset_linked</mat-icon>
      Ссылки
    </button>
    <button mat-menu-item
      (click)="openFeedback()">
      <mat-icon>chat</mat-icon>
      Обратная связь
    </button>
  </mat-menu>
</mat-toolbar>
}
@if(!(isMobile$ | async) || isPlatformServer) {
<mat-toolbar class="d-none d-lg-flex mat-elevation-z3">
  @if(!prod) {DEV}
  <div class="p-0 container d-flex align-items-center">
    <img class="logo-img d-none d-md-block me-auto"
      src="/assets/logo.webp"
      alt="D&D Campaign"
      routerLink="/home">
    <a mat-button
      disableRipple
      class="app-toolbar-button mx-1"
      routerLink="/home"
      routerLinkActive="active">
      <mat-icon svgIcon="home"
        class="me-md-1">
      </mat-icon>
      Приключения
    </a>
    <a mat-button
      disableRipple
      class="app-toolbar-button mx-1"
      routerLink="/spells"
      routerLinkActive="active">
      <mat-icon svgIcon="spell"
        class="me-md-1">
      </mat-icon>
      Заклинания
    </a>
    <a mat-button
      disableRipple
      class="app-toolbar-button mx-1"
      routerLink="/classes"
      routerLinkActive="active">
      <mat-icon svgIcon="barbarian"
        class="me-md-1">
      </mat-icon>
      Классы
    </a>
    <a mat-button
      disableRipple
      class="app-toolbar-button mx-1"
      routerLink="/races"
      routerLinkActive="active">
      <mat-icon svgIcon="wisdom"
        class="me-md-1">
      </mat-icon>
      Расы
    </a>


    @if(!(isTablet$ | async)) {
    @if(navigationDisabled$ | async) {
    <a mat-button
      disableRipple
      class="app-toolbar-button mx-1 navigation-disabled"
      (click)="showBestiaryMessage()">
      <mat-icon svgIcon="bestiary"
        class="me-md-1">
      </mat-icon>
      Бестиарий
    </a>
    <a mat-button
      disableRipple
      class="app-toolbar-button mx-1 navigation-disabled"
      (click)="showMessage()">
      <mat-icon svgIcon="items"
        class="me-md-1">
      </mat-icon>
      Предметы
    </a>
    }
    @else {
    <a mat-button
      disableRipple
      class="app-toolbar-button mx-1"
      routerLink="/monsters"
      routerLinkActive="active">
      <mat-icon svgIcon="bestiary"
        class="me-md-1">
      </mat-icon>
      Бестиарий
    </a>
    <a mat-button
      disableRipple
      class="app-toolbar-button mx-1"
      routerLink="/magic-items"
      routerLinkActive="active">
      <mat-icon svgIcon="items"
        class="me-md-1">
      </mat-icon>
      Предметы
    </a>
    }
    }

    @if(isAdmin$ | async) {
    <button mat-icon-button
      class="ms-auto"
      color="accent"
      title="В режим администратора"
      routerLink="/admin/spells">
      <mat-icon>shield_person</mat-icon>
    </button>
    } @else {
    <a mat-button
      class="ms-auto"
      color="accent"
      href="//boosty.to/dnd-campaign"
      target="_blank">
      <mat-icon class="app-icon-lg"
        svgIcon="boosty-color">
      </mat-icon>
      Поодержать Проект
    </a>
    }
    <button mat-icon-button
      class="mx-1"
      (click)="changeTheme()">
      <mat-icon>{{(isDarkTheme$ | async) ? 'light_mode' : 'dark_mode'}}</mat-icon>
    </button>
    @if(user$ | async) {
    <a mat-button
      color="primary"
      class="app-profile-button mx-1 px-3"
      routerLink="/profile"
      routerLinkActive="active">
      Личный кабинет
    </a>
    } @else {
    <button mat-button
      color="accent"
      class="app-profile-button mx-1 px-2"
      (click)="login()">
      Войти
    </button>
    }
    @if(isTablet$ | async) {
    <button mat-icon-button
      [matMenuTriggerFor]="extraLinks"
      class="app-toolbar-button">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #extraLinks="matMenu">
      @if(navigationDisabled$ | async) {
      <button mat-menu-item
        disableRipple
        class="navigation-disabled"
        (click)="showBestiaryMessage()">
        <mat-icon svgIcon="bestiary"
          class="me-1">
        </mat-icon>
        Бестиарий
      </button>
      <button mat-menu-item
        disableRipple
        class="navigation-disabled"
        (click)="showMessage()">
        <mat-icon svgIcon="items"
          class="me-1">
        </mat-icon>
        Предметы
      </button>
      }
      @else {
      <button mat-menu-item
        routerLink="/monsters"
        routerLinkActive="active">
        <mat-icon svgIcon="bestiary"
          class="me-1">
        </mat-icon>
        Бестиарий
      </button>
      <button mat-menu-item
        routerLink="/magic-items"
        routerLinkActive="active">
        <mat-icon svgIcon="items"
          class="me-1">
        </mat-icon>
        Предметы
      </button>
      }
    </mat-menu>
    }
  </div>
</mat-toolbar>
}

<ng-template #message
  let-release="release">
  Мы ещё работаем над этой страницей, и планируем ее выпуск на 1 квартал 2025 г., а ты можешь следить за новостями и
  поддержать нас платной подпиской на
  <a href="//boosty.to/dnd-campaign"
    target="_blank">Boosty</a>
  или в
  <a href="//vk.com/dnd_campaign"
    target="_blank">ВК</a>.
</ng-template>

<ng-template #bestiaryMessage
  let-release="release">
  Мы ещё работаем над этой страницей, и планируем ее выпуск на 2-3 квартал 2025 г., а ты можешь следить за новостями и
  поддержать нас платной подпиской на
  <a href="//boosty.to/dnd-campaign"
    target="_blank">Boosty</a>
  или в
  <a href="//vk.com/dnd_campaign"
    target="_blank">ВК</a>, для тебя же стараемся =)
</ng-template>