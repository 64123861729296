import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatDivider } from '@angular/material/divider';

import { AppService, ScreenService } from '@core/services';
import { AppConstants } from '@core/app-constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'app-footer d-none d-lg-flex ' },
  imports: [AsyncPipe, RouterModule, MatDivider],
})
export class AppFooterComponent {
  isMobile$ = this.screen.isMobile$;

  aboutLink = `//${AppConstants.URL}/about`;

  appVersion = AppConstants.RELEASE_VERSION;

  isDarkTheme$ = this.appService.isDarkTheme$;

  constructor(private appService: AppService, private screen: ScreenService) {}
}
