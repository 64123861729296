import { Component, Inject, Optional } from '@angular/core';

import { PlatformService, SeoService } from '@core/services';
import { RESPONSE } from '@core/server.response';
import { AppConstants } from '@core/app-constants';

@Component({
  standalone: true,
  selector: 'app-not-found',
  template: `
    <h1>Мы не смогли найти эту страницу</h1>
    <p>
      Напишите нам в <a href="//vk.com/dnd_campaign" target="_blank">ВК</a>, если вы уверены, что здесь точно должно
      быть что-то.
    </p>
  `,
  host: { class: 'app-page' },
})
export class NotFoundComponent {
  constructor(
    @Optional() @Inject(RESPONSE) private response: any,
    private platform: PlatformService,
    private seoService: SeoService
  ) {}

  ngOnInit() {
    this.seoService.set({
      title: 'Страница не найдена - D&D Campaign',
      description: 'Страница не найдена - D&D Campaign',
    });
    if (this.platform.isServer && this.response) {
      this.response.statusCode = 404;
      this.response.statusMessage = '404 - Page Not Found';
    }
  }
}
