import { Component } from '@angular/core';

import { SeoService } from '@core/services';

import { FirstComponent } from './screens/first/first.component';
import { GreetingsComponent } from './screens/greetings/greetings.component';
import { PageComponent } from './screens/page/page.component';
import { LastComponent } from './screens/last/last.component';
import { PAGES } from './screens/pages';

@Component({
  selector: 'app-about',
  imports: [FirstComponent, GreetingsComponent, PageComponent, LastComponent],
  templateUrl: './about.component.html',
  styleUrl: './about.component.scss',
  host: { class: 'app-about' },
})
export class AboutComponent {
  pages = PAGES;

  constructor(private seoService: SeoService) {}

  ngOnInit() {
    this.seoService.set({
      title: 'О сервисе - D&D Campaign',
      description:
        'D&D Campaign - описание, идеи, планы, текущие и будущие возможности: цифровой лист персонажа, ведение приключений и кампаний, SRD заклинания, расы и классы.',
    });
  }
}
