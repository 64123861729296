@if(!(isMobile$ | async)) {
<mat-divider class="mb-1"></mat-divider>
<div class="app-version app-text-secondary">v{{appVersion}}</div>
<div class="container d-flex mt-3 mb-2">
  <div>
    <div class="app-text-secondary app-text-uppercase mb-1">DND Campaign</div>
    <div class="d-flex align-items-center">
      <a routerLink="/docs/license">Пользовательское соглашение</a>
      <span class="mx-1">/</span>
      <a routerLink="/docs/cookie">Cookie</a>
      <span class="mx-1">/</span>
      <a routerLink="/about">О проекте</a>
    </div>
  </div>
  <div class="mx-auto">
    <div class="app-text-secondary app-text-uppercase mb-1">Поддержите нас</div>
    <div class="d-flex align-items-center">
      <a href="//boosty.to/dnd-campaign"
        target="_blank">
        Boosty
      </a>
      <span class="mx-1">/</span>
      <a href="//vk.com/donut/dnd_campaign"
        target="_blank">
        ВК
      </a>
    </div>
  </div>
  <div>
    <div class="app-text-secondary app-text-uppercase">Следите за развитием проекта</div>
    <div class="d-flex align-items-center">
      <a class="app-social"
        href="//youtube.com/@dnd-campaign"
        target="_blank">
        <img
          [src]="(isDarkTheme$ | async) ? 'assets/icons/social/youtube-color.svg' : 'assets/icons/social/youtube.svg'"
          alt="DND Campaign на YouTube">
      </a>
      <a class="app-social"
        href="//t.me/dnd_campaign"
        target="_blank">
        <img
          [src]="(isDarkTheme$ | async) ? 'assets/icons/social/telegram-color.svg' : 'assets/icons/social/telegram-dark.svg'"
          alt="DND Campaign в Telegram">
      </a>
      <a class="app-social"
        href="//vk.com/dnd_campaign"
        target="_blank">
        <img [src]="(isDarkTheme$ | async) ? 'assets/icons/social/vk-white.svg' : 'assets/icons/social/vk-black.svg'"
          alt="DND Campaign в ВК">
      </a>
      <a class="app-social"
        href="//boosty.to/dnd-campaign"
        target="_blank">
        <img class="normalized-height"
          [src]="(isDarkTheme$ | async) ? 'assets/icons/social/boosty-small-white.svg' : 'assets/icons/social/boosty-small-dark.svg'"
          alt="DND Campaign на Boosty">
      </a>
    </div>
  </div>
</div>
}