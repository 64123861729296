import {
  afterNextRender,
  Component,
  EmbeddedViewRef,
  HostBinding,
  Inject,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DatePipe, DOCUMENT, Location } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { filter, takeUntil } from 'rxjs';

import { Metrika } from '@modules/yandex-metrika';

import { ApiService } from '@api/services';

import { AppService, PlatformService, StorageService } from '@core/services';
import { BaseTakeUntil } from '@core/helpers';
import { AppConstants } from '@core/app-constants';

import { AuthService } from '@modules/auth';
import { SvgIconsService } from '@modules/svg-icons';

const datePipe = new DatePipe('ru');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, RouterLink],
})
export class AppComponent extends BaseTakeUntil {
  @ViewChild('cookieTemplate') cookieTemplate!: TemplateRef<unknown>;

  cookieSnackRef?: MatSnackBarRef<EmbeddedViewRef<any>>;

  @HostBinding('attr.app-version') releaseVersion = AppConstants.RELEASE_VERSION;
  @HostBinding('attr.app-release-date') releaseDate = datePipe.transform(AppConstants.RELEASE_DATE, 'd MMM yyyy');
  url = AppConstants.URL;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private location: Location,
    private renderer: Renderer2,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private metrika: Metrika,
    private platform: PlatformService,
    private appService: AppService,
    private authService: AuthService,
    private apiService: ApiService,
    private svgIcons: SvgIconsService,
    private storage: StorageService
  ) {
    super();
    afterNextRender(() => this.load());
  }

  ngOnInit() {
    this.initMetrika();
    this.initTheme();
    this.svgIcons.register();
  }

  initMetrika() {
    if (this.platform.isServer) return;
    let prevPath = this.location.path();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const newPath = this.location.path();
      this.metrika.hit(newPath, { referer: prevPath });
      prevPath = newPath;
    });
  }

  initTheme() {
    if (this.platform.isServer) return;
    this.appService.isDarkTheme$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => this.document.body.setAttribute('theme', data ? 'dark' : 'light'));
    this.renderer.addClass(this.document.body, !AppConstants.LOCAL ? 'app-hide-scrollbar' : 'app-show-scrollbar');
  }

  load() {
    this.appService.loadData();
    this.authService.auth$.pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data?.access_token) {
        if (!this.storage.getItem('cookieAccepted')) this.acceptCookie();
        if (!this.appService.getUser()) {
          this.apiService.getCurrentUser().subscribe(data => this.appService.setUser(data));
        }
      } else this.appService.setUser(null);
    });
    if (!this.storage.getItem('cookieAccepted')) this.showCookie();
  }

  private showCookie() {
    this.cookieSnackRef = this.matSnackBar.openFromTemplate(this.cookieTemplate);
  }

  acceptCookie() {
    this.storage.setItem('cookieAccepted', true);
    this.cookieSnackRef?.dismiss();
  }
}
