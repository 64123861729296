<mat-form-field [color]="color"
  [matTooltip]="control?.errors | errorTooltip"
  [matTooltipDisabled]="!control?.touched || control?.valid || !(control?.errors | errorTooltip)">
  @if (label) {<mat-label>{{label}}</mat-label>}
  <input autocomplete="off"
    matInput
    [attr.min]="min"
    [attr.max]="max"
    [placeholder]="placeholder"
    [type]="type"
    [formControl]="$any(control)"
    (keyup)="keyup($event)">
  <ng-content></ng-content>
</mat-form-field>