import { FormControl } from '@angular/forms';

import { AppFormGroup } from '@core/helpers';

export interface ProfileFormValue {
  Nickname: string | null;
}

export class ProfileForm extends AppFormGroup<ProfileFormValue> {
  constructor() {
    super({ Nickname: new FormControl<string | null>(null) });
  }
}
