<app-card-gallery [data]="(characters$ | async)!"
  [cardTemplate]="cardTemplate"
  [cols]="(cols$ | async)!"
  [rows]="(characters$ | async)!.length > (cols$ | async)! ? 2 : 1">
  <ng-template #cardTemplate
    let-item="item">
    <app-character-card [data]="item"
      (click)="openCharacter(item)">
    </app-character-card>
  </ng-template>
</app-card-gallery>