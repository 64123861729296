import { Component, HostBinding, Input } from '@angular/core';

import { MarkdownModule } from 'ngx-markdown';

@Component({
  imports: [MarkdownModule],
  selector: 'app-markdown',
  template: `<markdown [data]="_data"></markdown>`,
  host: { class: 'app-markdown' },
})
export class MarkdownComponent {
  protected _data: string | null = null;
  @Input() set data(value: string | null | undefined) {
    this._data = this.cleanupClassFeaturesLinks(value);
  }

  private cleanupClassFeaturesLinks(data: string | null | undefined) {
    // TODO: исправить на внутренние ссылки
    const regexp = /\[(.*?)\]\(.*?\)/g;
    return (data || '').replaceAll(regexp, '**$1**');
  }
}
