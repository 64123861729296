import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { SVG_ICONS } from './svg-icons';

@Injectable({
  providedIn: 'root',
})
export class SvgIconsService {
  private registered = false;

  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  register() {
    if (this.registered) return;
    this.iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    SVG_ICONS.forEach(item =>
      this.iconRegistry.addSvgIcon(
        item.split('/').reverse()[0],
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${item}.svg`)
      )
    );
    this.registered = true;
  }
}
