import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { ThemePalette } from '@angular/material/core';
import { takeUntil } from 'rxjs';

import { BaseTakeUntil } from '@core/helpers';

import { ErrorTooltipPipe } from '@modules/common';

import { HTMLInputTypeAttribute } from './input.type';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  host: {
    class: 'app-form-field',
    '[class.app-form-field-text-highlighted]': 'textHighlighted',
    '[class.app-form-field-minified]': 'minified',
  },
  imports: [ReactiveFormsModule, MatFormField, MatInput, MatTooltip, MatLabel, ErrorTooltipPipe],
})
export class InputComponent extends BaseTakeUntil implements OnInit {
  @Input() control!: AbstractControl | FormControl | null;
  @Input() label?: string;
  @Input() placeholder: string = '';
  @Input() min?: number;
  @Input() max?: number;
  @Input() type: HTMLInputTypeAttribute = 'text';
  @Input() color: ThemePalette;
  @Input() textHighlighted = false;
  @Input() minified = false;
  @Input() nullable = false;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.control?.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.detectChanges());
  }

  keyup(event: KeyboardEvent) {
    if (this.type !== 'number') return;

    const element = event.target as HTMLInputElement;

    if (this.nullable && element.value === '') return this.control?.setValue(null, { emitModelToViewChange: false });

    if (element.value === '') return this.control?.setValue(0, { emitModelToViewChange: false });

    if (parseInt(element.value) < parseInt(element.min))
      this.control?.setValue(element.min, { emitModelToViewChange: true });
    else if (parseInt(element.value) > parseInt(element.max))
      this.control?.setValue(element.max, { emitModelToViewChange: true });
    else if (typeof element.value === 'string') this.control?.setValue(+element.value, { emitModelToViewChange: true });
  }
}
