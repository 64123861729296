<app-card>
  <app-card-header>
    <app-card-title>Правовая информация и ссылки</app-card-title>
  </app-card-header>
  <div class="app-text-secondary app-text-uppercase mb-2">DND Campaign</div>
  <a class="d-block my-2"
    routerLink="/docs/license"
    (click)="close()">
    Пользовательское соглашение
  </a>
  <a class="d-block my-2"
    routerLink="/docs/cookie"
    (click)="close()">
    Cookie
  </a>
  <a class="d-block my-2"
    routerLink="/about"
    (click)="close()">
    О проекте
  </a>
  <mat-divider class="my-3"></mat-divider>
  <div class="app-text-secondary app-text-uppercase mb-2">Поддержите нас</div>
  <a class="d-block my-2"
    href="//boosty.to/dnd-campaign"
    target="_blank">
    Boosty
  </a>
  <a class="d-block my-2"
    href="//vk.com/donut/dnd_campaign"
    target="_blank">
    ВК
  </a>
  <mat-divider class="my-3"></mat-divider>
  <div>
    <div class="app-text-secondary app-text-uppercase mb-2">Следите за развитием проекта</div>
    <div class="d-flex align-items-center">
      <a class="app-social"
        href="//youtube.com/@dnd-campaign"
        target="_blank">
        <img
          [src]="(isDarkTheme$ | async) ? 'assets/icons/social/youtube-color.svg' : 'assets/icons/social/youtube.svg'"
          alt="DND Campaign на YouTube">
      </a>
      <a class="app-social"
        href="//t.me/dnd_campaign"
        target="_blank">
        <img
          [src]="(isDarkTheme$ | async) ? 'assets/icons/social/telegram-color.svg' : 'assets/icons/social/telegram-dark.svg'"
          alt="DND Campaign в Telegram">
      </a>
      <a class="app-social"
        href="//vk.com/dnd_campaign"
        target="_blank">
        <img [src]="(isDarkTheme$ | async) ? 'assets/icons/social/vk-white.svg' : 'assets/icons/social/vk-black.svg'"
          alt="DND Campaign в ВК">
      </a>
      <a class="app-social"
        href="//boosty.to/dnd-campaign"
        target="_blank">
        <img class="normalized-height"
          [src]="(isDarkTheme$ | async) ? 'assets/icons/social/boosty-small-white.svg' : 'assets/icons/social/boosty-small-dark.svg'"
          alt="DND Campaign на Boosty">
      </a>
    </div>
  </div>
  <div class="app-text-right app-text-secondary">v{{appVersion}}</div>
</app-card>