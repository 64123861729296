import { AchievementType } from '@api/types';

const ACHIEVEMENT_IMAGES = [
  'top1_registered_users',
  'top10_registered_users',
  'top100_registered_users',
  'donation_1000',
  'donation_10000',
  'donation_25000',
];

export function getAchievementImage(type: AchievementType, isAchieved: boolean) {
  return `/assets/illustrations/achievements/${ACHIEVEMENT_IMAGES[type]}${!isAchieved ? '_bw' : ''}.webp`;
}
