<div class="container">
  <h1>Уведомление об использовании файлов куки (cookie)</h1>
  <h2>Файлы cookie</h2>

  <p>
    Файлы cookie – это небольшие фрагменты текста, передаваемые в браузер с сайта, который Вы открываете. С их помощью
    сайт запоминает информацию о Ваших посещениях. Это упрощает взаимодействие с сайтом и делает его полезнее для Вас.
    В
    этих целях также используются другие подобные технологии, включая уникальные идентификаторы (они нужны, чтобы
    различать приложения или устройства), теги пикселей и локальное хранилище. Файлы cookie и другие подобные
    технологии, описанные на текущей странице, могут применяться в указанных ниже целях.
  </p>

  <h3>Аналитика</h3>
  <p>
    Файлы cookie и другие подобные технологии, применяющиеся для аналитики, помогают собирать данные, с помощью
    которых мы определяем, как Вы взаимодействуете с нашим сервисом. Эта статистика позволяет нам улучшать контент и
    разрабатывать функции, повышающие удобство использования.
  </p>

  <h3>Как управлять файлами cookie?</h3>
  <p>
    Большинство интернет-браузеров изначально настроены на автоматический прием файлов cookie.
    <br>
    В любое время Вы можете изменить настройки Вашего браузера таким образом, чтобы блокировать файлы cookie или
    предупреждать Вас о том, когда они будут отправляться к Вам на устройство (обратитесь к руководству использования
    конкретного браузера). Отключение файлов cookie может повлиять на Вашу работу в интернете.
    <br>
    Если Вы используете несколько устройств и (или) браузеров для доступа в интернет, соответствующие настройки должны
    быть изменены в каждом из них.
  </p>

  <h3>Файлы cookie и персональные данные</h3>
  <p>
    Файлы cookie не содержат Ваших персональных данных, благодаря которым возможна идентификация Вашей личности. Файлы
    cookie не содержат фамилии, имени, отчества, адресов электронной почты, домашнего адреса, номеров телефонов и
    прочих
    подобных данных.
  </p>
</div>