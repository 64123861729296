import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';

import { ApiCampaignListItem } from '@api/types';

import { CardComponent, CardHeaderComponent, CardTitleDirective } from '@modules/card';

@Component({
  selector: 'app-campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CardComponent, CardHeaderComponent, CardTitleDirective, MatTooltip, MatIcon, DatePipe],
})
export class CampaignCardComponent {
  @Input() data!: ApiCampaignListItem;
}
