<app-card>
  <app-card-header>
    <app-card-title class="app-text-ellipsis"
      [matTooltip]="data.Name.toLocaleUpperCase()"
      [matTooltipShowDelay]="500">
      {{data.Name}}
    </app-card-title>
  </app-card-header>
  <div class="app-line">
    <mat-icon>event</mat-icon>
    Последняя Встреча: {{(data.LastGameDate | date: 'dd.MM.yyyy') || 'нет данных'}}
  </div>
  <div class="app-line">
    <mat-icon>location_on</mat-icon>
    Последнее Место: {{data.LastGamePlace || 'нет данных'}}
  </div>
</app-card>