import { afterNextRender, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { ApiAuthRoleType, ApiAuthToken } from '@api/types';

import { StorageService } from '@core/services';
import { JwtToken } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authSource$ = new BehaviorSubject<ApiAuthToken | null>(null);
  auth$ = this.authSource$.asObservable();
  private setAuth(value: ApiAuthToken | null) {
    const role = JwtToken.parse(value?.access_token)?.['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
    this.setRole(role || 'User');
    this.authSource$.next(value);
  }
  getAuthData() {
    return this.authSource$.getValue();
  }
  getAccessToken() {
    return this.authSource$.getValue()?.access_token;
  }

  private roleSource$ = new BehaviorSubject<ApiAuthRoleType>('User');
  role$ = this.roleSource$.asObservable();
  private setRole(value: ApiAuthRoleType) {
    this.roleSource$.next(value);
  }
  getRole() {
    return this.roleSource$.getValue();
  }

  constructor(private router: Router, private storage: StorageService) {
    afterNextRender(() => {
      const data = this.storage.getItem('auth');
      if (data) this.setAuth(data);
    });
  }

  authenticate(data: ApiAuthToken | null) {
    this.setAuth(data);
    data ? this.storage.setItem('auth', data) : this.storage.removeItem('auth');
  }

  isAuthenticated(): boolean {
    const token = this.getAccessToken();
    if (!token) return false;
    const parsed = JwtToken.parse(token);
    if (!parsed) return false;
    return Date.now() <= parsed.exp * 1000;
  }

  logout() {
    this.authenticate(null);
    this.router.navigate(['/']);
  }
}
