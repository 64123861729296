<form [formGroup]="form"
  class="form"
  (ngSubmit)="login()">
  <div class="logo-container">
    <img class="logo-img"
      src="/assets/logo.webp"
      alt="D&D Campaign">
  </div>
  <app-input class="mb-3"
    placeholder="Почта для входа"
    type="email"
    [control]="form.get('Email')">
    <mat-icon class="me-2 app-order-n1">mail</mat-icon>
  </app-input>
  <app-input class="mb-3"
    placeholder="Пароль"
    [type]="passwordHidden ? 'password' : 'text'"
    [control]="form.get('Password')">
    <mat-icon class="app-pointer me-2 app-order-n1"
      (click)="passwordHidden = !passwordHidden">
      {{passwordHidden ? 'password' : 'visibility'}}
    </mat-icon>
  </app-input>
  <button mat-raised-button
    type="submit"
    color="accent"
    class="submit-button mt-4">
    Войти
  </button>
  <a class="nav-link app-text-uppercase mat-caption my-3 py-3 app-pointer"
    (click)="register()">
    Зарегестрироваться
  </a>
  <a class="nav-link app-text-uppercase app-text-bold py-3"
    href="//boosty.to/dnd-campaign">
    Поддержите нас на Boosty!
  </a>
</form>