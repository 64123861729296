import { FormControl } from '@angular/forms';

import { AppFormGroup, AppValidators } from '@core/helpers';

export interface LoginFormValue {
  Email: string;
  Password: string;
  IsCookiesNoticeAccepted: boolean;
  IsServiceLegalAccepted: boolean;
}

export class RegisterForm extends AppFormGroup<LoginFormValue> {
  constructor() {
    super({
      Email: new FormControl(null, [AppValidators.required, AppValidators.email]),
      Password: new FormControl(null, AppValidators.required),
      IsCookiesNoticeAccepted: new FormControl(true, AppValidators.requiredTrue),
      IsServiceLegalAccepted: new FormControl(true, AppValidators.requiredTrue),
    });
  }
}
