import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RouterLink } from '@angular/router';

import { SocialsComponent } from '../../components/socials/socials.component';

@Component({
  selector: 'app-last',
  templateUrl: './last.component.html',
  styleUrls: ['./last.component.scss'],
  host: { class: 'app-about-page app-about-page-fullheight' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, SocialsComponent],
})
export class LastComponent {
  url = inject(DOCUMENT).location.origin;
}
