import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbar } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { map } from 'rxjs';

import { AppService, PlatformService, ScreenService, ToastrService } from '@core/services';
import { AppConstants } from '@core/app-constants';

import { ModalService } from '@modules/modal';
import { AuthService } from '@modules/auth';

import { LoginModalComponent } from '../login-modal/login-modal.component';
import { FooterModalComponent } from '../footer-modal/footer-modal.component';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'app-toolbar' },
  imports: [AsyncPipe, RouterModule, MatToolbar, MatButtonModule, MatIcon, MatMenuModule],
})
export class ToolbarComponent {
  @ViewChild('message', { read: TemplateRef }) message!: TemplateRef<unknown>;
  @ViewChild('bestiaryMessage', { read: TemplateRef }) bestiaryMessage!: TemplateRef<unknown>;
  user$ = this.appService.user$;

  isMobile$ = this.screen.isMobile$;

  isTablet$ = this.screen.isTablet$;

  isDarkTheme$ = this.appService.isDarkTheme$;

  prod = AppConstants.PROD;

  isAdmin$ = this.authService.role$.pipe(map(data => data === 'Admin'));

  navigationDisabled$ = this.isAdmin$.pipe(map(data => !data));

  isPlatformServer = this.platform.isServer;

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private modal: ModalService,
    private toastr: ToastrService,
    private screen: ScreenService,
    private platform: PlatformService
  ) {}

  login() {
    this.modal.open(LoginModalComponent, { width: 'auto' });
  }

  links() {
    this.modal.open(FooterModalComponent, { width: 'auto' });
  }

  showMessage() {
    this.toastr.showFromTempalate(this.message, { duration: 10000 });
  }

  showBestiaryMessage() {
    this.toastr.showFromTempalate(this.bestiaryMessage, { duration: 10000 });
  }

  changeTheme() {
    this.appService.setIsDarkTheme(!this.appService.getIsDarkTheme());
  }

  openFeedback() {
    this.modal.open(FeedbackModalComponent, { maxWidth: 400 });
  }
}
