import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MatTabNavPanel } from '@angular/material/tabs';
import { takeUntil } from 'rxjs';

import { SeoService } from '@core/services';
import { BaseTakeUntil } from '@core/helpers';

import { CharacterService } from './character.service';
import { CharacterNavbarComponent } from './character-navbar/character-navbar.component';

@Component({
  selector: 'app-character-view',
  templateUrl: './character-view.component.html',
  styleUrls: ['./character-view.component.scss'],
  host: { class: 'app-page' },
  imports: [RouterModule, MatTabNavPanel, CharacterNavbarComponent],
})
export class CharacterViewComponent extends BaseTakeUntil {
  data$ = this.characterService.data$;

  constructor(
    private route: ActivatedRoute,
    private seoService: SeoService,
    private characterService: CharacterService
  ) {
    super();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.characterService.loadCharacter(id);
    this.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => this.seoService.set({ title: `${data?.Name || 'Персонаж'} - D&D Campaign` }));
  }
}
