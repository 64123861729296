<div class="container">
  <h1>Пользовательское соглашение</h1>
  <p>
    Настоящий документ «Пользовательское соглашение» представляет собой предложение авторов сайта в лице Мерглодова
    Даниила Владимировича и Ананьева Александра Александровича (далее — «Администрация»), заключить договор на
    изложенных ниже условиях Соглашения.
  </p>
  <h2>Общие положения Пользовательского соглашения</h2>
  <ol>
    <li>
      В настоящем документе и вытекающих или связанным с ним отношениях Сторон применяются следующие термины и
      определения:
      <ol type="a">
        <li>Платформа — программно-аппаратные средства, интегрированные с Сайтом Администрации;</li>
        <li>Пользователь — дееспособное физическое лицо, присоединившееся к настоящему Соглашению в собственном интересе
          либо выступающее от имени и в интересах представляемого им юридического лица.</li>
        <li>Сайт — интернет-сайты, размещенные в домене dnd-campaign.ru и его поддоменах.</li>
        <li>Сервис — комплекс услуг <!-- и лицензия -->, предоставляемые Пользователю с использованием Платформы.</li>
        <li>Соглашение — настоящее соглашение со всеми дополнениями и изменениями.</li>
      </ol>
    <li>
      Использование вами Сайта любым способом и в любой форме в пределах его объявленных функциональных возможностей,
      включая:
      <ul>
        <li>просмотр размещенных на Сайте материалов;</li>
        <li>регистрация и/или авторизация на Сайте;</li>
        <!-- <li>размещение или отображение на Сайте любых материалов, включая но не ограничиваясь такими как: тексты,
          гипертекстовые ссылки, изображения, аудио и видео- файлы, сведения и/или иная информация;</li> -->
      </ul>
      создает договор на условиях настоящего Соглашения в соответствии с положениями ст.437 и 438 Гражданского кодекса
      Российской Федерации.
    </li>
    <li>
      Воспользовавшись любой из указанных выше возможностей по использованию Сервиса вы подтверждаете, что:
      <ol type="a">
        <li>Ознакомились с условиями настоящего Соглашения в полном объеме до начала использования Сервиса.</li>
        <li>Принимаете все условия настоящего Соглашения в полном объеме без каких-либо изъятий и ограничений с вашей
          стороны и обязуетесь их соблюдать или прекратить использование Сервиса. Если вы не согласны с условиями
          настоящего Соглашения или не имеете права на заключение договора на их основе, вам следует незамедлительно
          прекратить любое использование Сервиса.</li>
        <li>Соглашение (в том числе любая из его частей) может быть изменено Администрацией без какого-либо специального
          уведомления. Новая редакция Соглашения вступает в силу с момента ее размещения на Сайте либо доведения до
          сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения.</li>
      </ol>
    </li>
  </ol>

  <h2>Общие условия пользования Сервисом</h2>
  <ol>
    <li>
      Контент прдедставленный в Сервисе является объектом авторского права, исключительные права, на использование
      которого принадлежит Администрации. Не допускается копирование, распространение, перепечатка (целиком или
      частично), или иное использование материала без письменного разрешения Администрации.
    </li>
    <li>
      Исключением является контент являющийся переводом SRD 5.1, лицензия и условия его использования предоставлены на
      странице <a routerLink="/docs/content-license">Лицензия CC BY-NC-SA 4.0</a>. Контент предоставляемый в рамках этой
      лицензии имеет на своей странице ссылку на эту лицензию, если ссылки на лицензию на странице нет, значит на неё
      распространяются условия из пункта 1 выше.
    </li>
  </ol>

  <h2>Яндекс метрика</h2>
  <p>
    Настоящим соглашением вы уведомлены и соглашаетесь с <a href="https://yandex.ru/legal/metrica_termsofuse/">п.18
      пользовательского соглашения Яндекс.Метрики.</a>
  </p>
</div>