import { afterNextRender, ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { StorageService } from '@core/services';
import { AppConstants } from '@core/app-constants';

import { CardComponent, CardHeaderComponent, CardTitleDirective } from '@modules/card';

@Component({
  selector: 'app-about-banner',
  templateUrl: './about-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'container d-block px-1 px-lg-0' },
  imports: [MatButtonModule, MatIcon, CardComponent, CardHeaderComponent, CardTitleDirective],
})
export class AboutBannerComponent {
  protected aboutBannerHidden = true;

  protected aboutLink = `//${AppConstants.URL}/about`;

  constructor(private storage: StorageService) {
    afterNextRender(() => (this.aboutBannerHidden = !!this.storage.getItem('aboutBannerHidden')));
  }

  close(event: MouseEvent) {
    event.stopPropagation();
    this.storage.setItem('aboutBannerHidden', true);
    this.aboutBannerHidden = true;
  }
}
