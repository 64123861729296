import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { map } from 'rxjs';

import { AppService, SeoService } from '@core/services';
import { AppConstants } from '@core/app-constants';

import { ModalService } from '@modules/modal';
import { AuthService } from '@modules/auth';

import { CampaignListComponent } from '../campaigns/campaign-list/campaign-list.component';
import { CharacterListComponent } from '../characters/character-list/character-list.component';
import { LoginModalComponent } from '../components/login-modal/login-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'app-page' },
  imports: [MatButtonModule, MatIconModule, CampaignListComponent, CharacterListComponent],
})
export class HomeComponent {
  isAdmin$ = this.authService.role$.pipe(map(data => data === 'Admin'));

  constructor(
    private router: Router,
    private seoService: SeoService,
    private authService: AuthService,
    private appService: AppService,
    private modal: ModalService
  ) {
    this.seoService.set({ title: 'Приключения - D&D Campaign', description: AppConstants.DEFAULT_DESCRIPTION });
  }

  addCampaign() {
    if (this.appService.getUser()) {
      this.router.navigate(['campaigns/add']);
    } else {
      this.modal.open(LoginModalComponent, { width: 'auto', data: { successRoute: 'campaigns/add' } });
    }
  }

  addCharacter() {
    if (this.appService.getUser()) {
      this.router.navigate(['characters/add']);
    } else {
      this.modal.open(LoginModalComponent, { width: 'auto', data: { successRoute: 'characters/add' } });
    }
  }
}
