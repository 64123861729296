<div class="card-gallery-container-wrapper"
  [@cardGalleryAnimation]="page$ | async">
  <div *appCardGalleryAnimator="page$ | async"
    class="card-gallery-container"
    (swipeleft)="nextPage()"
    (swiperight)="prevPage()">
    @for (item of items$ | async; track item) {
    <ng-container [ngTemplateOutlet]="cardTemplate"
      [ngTemplateOutletContext]="{item}">
    </ng-container>
    }
  </div>
</div>
@if (totalPages$ | async; as totalPages) {
@if (totalPages > 1) {
@if (paginationType === 'pages') {
<app-card-gallery-pages-paginator [totalPages]="totalPages"
  [itemsPerPage]="(itemsPerPage$ | async)!"
  [totalItems]="(data$ | async)!.length"
  [page]="(page$ | async)!"
  (pageChange)="setPage($event)">
</app-card-gallery-pages-paginator>
} @else {
<app-card-gallery-dots-paginator [totalPages]="totalPages"
  [page]="(page$ | async)!"
  (pageChange)="setPage($event)">
</app-card-gallery-dots-paginator>
}
}
}