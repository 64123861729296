import { FormGroup, AbstractControl, ValidatorFn, AbstractControlOptions, AsyncValidatorFn } from '@angular/forms';
import { ApiHttpErrorResponse } from '@api/types';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

import { AppFormArray } from './form-array';
import { GetUUID } from './uuid';

interface MapperResponse {
  field: string;
  message: string[];
}

// Replace Array[0].Name to Array.0.Name, which allows to use thi string in form.get() function
export const DEFAULT_FIELD_MAPPER = (field: string) =>
  field.replace(/[\[]/gi, '.').replace(/[\]]/gi, '').replace(/\$./gi, '');

const DEFAULT_ERROR_MAPPER = (field: string, model: any): MapperResponse => ({
  field: DEFAULT_FIELD_MAPPER(field),
  message: model[field],
});

export class AppFormGroup<T> extends FormGroup<any> {
  _id = GetUUID(); // need to get for loop track by parameter

  private valueSource$ = new BehaviorSubject<T>(this.value);
  value$ = this.valueSource$.asObservable();

  destroy$ = new Subject<void>();

  // Потенциальноможно удалить будет, так как изменения будут сохраняться сразу посредством вызова апи. Пока что используется в CampaignDetails
  private initialValue: T | null = null;
  updateInitialValue() {
    this.initialValue = this.getRawValue();
  }
  hasValueChanged() {
    return this.initialValue !== this.getRawValue();
  }
  hasPropertyChanged(prop: keyof T) {
    if (typeof this.initialValue?.[prop] === 'string')
      return (this.initialValue?.[prop] as string)?.trim() !== (this.getRawValue()?.[prop] as string)?.trim();
    else return this.initialValue?.[prop] !== this.getRawValue()[prop];
  }

  private errorMapper = DEFAULT_ERROR_MAPPER;

  constructor(
    controls: { [key in keyof T]: AbstractControl },
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls, validatorOrOpts, asyncValidator);
    this.updateInitialValue();
    this.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(data => this.valueSource$.next({ ...data }));
  }

  override setValue(
    value: { [key in keyof T]: T[key] },
    options?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined } | undefined
  ): void {
    super.setValue(value, options);
    this.updateInitialValue();
  }

  override patchValue(
    value: Partial<{ [key in keyof T]: T[key] }>,
    options?: { onlySelf?: boolean | undefined; emitEvent?: boolean | undefined } | undefined
  ): void {
    super.patchValue(value, options);
    this.updateInitialValue();
  }

  override getRawValue() {
    return super.getRawValue() as T;
  }

  validate() {
    if (this.invalid) {
      this.updateValueAndValidity();
      this.markAllAsTouched();
    }
    return this.valid;
  }

  destroy() {
    Object.values(this.controls).forEach(item => this.destroyForm(item));
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private destroyForm(data: AbstractControl) {
    if ((data instanceof AppFormGroup || data instanceof AppFormArray) && data.destroy && !data.destroy$.closed) {
      data.destroy();
    }
  }

  // emitEvent: false - потенциально может сломать обновление состояния инпутов
  // добавленно, для того чтобы избежать бесконечного цикла valueChanges, так как на него подписаны character patch запросы
  private _errorHandler = (error: ApiHttpErrorResponse) => {
    const errors = error?.error?.Value?.ModelState || error?.error?.errors || {};
    Object.keys(errors)
      .map(field => this.errorMapper(field, errors))
      .forEach(error => {
        this.get(error.field)?.setErrors({ error: error.message }, { emitEvent: false });
        this.get(error.field)?.markAllAsTouched({ emitEvent: false });
      });
    this.updateValueAndValidity({ emitEvent: false });
  };
  public get errorHandler() {
    return this._errorHandler;
  }
  public set errorHandler(value) {
    this._errorHandler = value;
  }
}
