<div class="container">
  <h1>Лицензия на использование SRD 5.1</h1>
  <h2>Оригинал</h2>
  <p>
    This work includes material taken from the System Reference Document 5.1 (“SRD 5.1”) by Wizards of
    the Coast LLC and available at <a
      href="https://dnd.wizards.com/resources/systems-reference-document">https://dnd.wizards.com/resources/systems-reference-document</a>.
    The SRD 5.1 is licensed under the Creative Commons Attribution 4.0 International License available at
    <a
      href="https://creativecommons.org/licenses/by/4.0/legalcode">https://creativecommons.org/licenses/by/4.0/legalcode</a>.
  </p>
  <h2>Перевод</h2>
  <p>
    Эта работа содержит материалы взятые из System Reference Document 5.1 (“SRD 5.1”) за авторством Wizards of the Coast
    LLC доступном по <a
      href="https://dnd.wizards.com/resources/systems-reference-document">https://dnd.wizards.com/resources/systems-reference-document</a>.
    SRD 5.1 доступен в рамках Creative Commons Attribution 4.0 International License
    <a
      href="https://creativecommons.org/licenses/by/4.0/legalcode">https://creativecommons.org/licenses/by/4.0/legalcode</a>.
  </p>
</div>