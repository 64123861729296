import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getLookupItemById',
  standalone: true,
})
export class GetLookupItemById implements PipeTransform {
  transform<T extends { Id: Guid | number }>(data: T[] | null, id?: Guid | number | null): T | undefined {
    return (data || []).find(item => item.Id === id);
  }
}

@Pipe({
  name: 'getLookupItemsByIds',
  standalone: true,
})
export class GetLookupItemsByIds implements PipeTransform {
  transform<T extends { Id: Guid | number }>(
    data: T[] | null,
    ids: Array<Guid | number> = [],
    params?: { skipFilteringIfEmpty: boolean }
  ): T[] {
    return params?.skipFilteringIfEmpty && !ids.length ? data || [] : (data || []).filter(({ Id }) => ids.includes(Id));
  }
}
