import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormArray, ValidatorFn } from '@angular/forms';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { BehaviorSubject, startWith, Subject, takeUntil } from 'rxjs';

import { AppFormGroup } from './form-group';

export class AppFormArray<F extends AbstractControl<V, V>, V> extends FormArray<any> {
  destroy$ = new Subject<void>();

  private controlsSource$ = new BehaviorSubject<F[]>([]);
  controls$ = this.controlsSource$.asObservable();

  private valueSource$ = new BehaviorSubject<V[]>([]);
  value$ = this.valueSource$.asObservable();

  constructor(
    // !important `F` should be NON abstract class
    private controlFactory: new (value?: V, options?: { onlySelf?: boolean; emitEvent?: boolean }) => F,
    controls?: Array<{ [key in keyof V]: AbstractControl }>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(controls ?? [], validatorOrOpts, asyncValidator);
    this.valueChanges
      .pipe(takeUntil(this.destroy$), startWith(this.value))
      .subscribe(data => this.valueSource$.next([...data]));
  }

  private updateControlsSource() {
    this.controlsSource$.next([...this.getControls()]);
  }

  // !important do NOT use it on tempate binding
  getControls() {
    return this.controls as F[];
  }

  override getRawValue() {
    return super.getRawValue() as V[];
  }

  addItem(data: F) {
    this.push(data);
    this.markAsTouched();
    this.markAsDirty();
    this.updateControlsSource();
  }

  addNew(value?: V) {
    this.push(new this.controlFactory(value));
    this.markAsTouched();
    this.markAsDirty();
    this.updateControlsSource();
  }

  copyItem(data: F) {
    this.push(new this.controlFactory(data.getRawValue()));
    this.markAsTouched();
    this.markAsDirty();
    this.updateControlsSource();
  }

  removeItem(data: F) {
    this.destroyForm(data);
    this.removeAt(this.getControls().findIndex(item => item === data));
    this.markAsTouched();
    this.markAsDirty();
    this.updateControlsSource();
  }

  override reset(value?: any, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.clear(options);
    super.reset(value, options);
  }

  override push(control: F, options?: { emitEvent?: boolean }): void {
    super.push(control, options);
    this.updateControlsSource();
  }

  override insert(index: number, control: F, options?: { emitEvent?: boolean }): void {
    super.insert(index, control, options);
    this.updateControlsSource();
  }

  override removeAt(index: number, options?: { emitEvent?: boolean }): void {
    super.removeAt(index, options);
    this.updateControlsSource();
  }

  override setControl(index: number, control: F, options?: { emitEvent?: boolean }): void {
    super.setControl(index, control, options);
  }

  override setValue(value: V[], options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.clear(options);
    value.forEach(item => super.push(new this.controlFactory(item, options), options));
    super.setValue(value, options);
    this.updateControlsSource();
  }

  // todo should be partial update instead of clearing and re adding
  override patchValue(value: V[], options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.clear(options);
    (value ?? []).forEach(item => super.push(new this.controlFactory(item, options), options));
    super.patchValue(value, options);
    this.updateControlsSource();
  }

  override clear(options?: { emitEvent?: boolean }): void {
    super.clear(options);
    this.updateControlsSource();
  }

  validate() {
    if (this.invalid) {
      this.updateValueAndValidity();
      this.markAllAsTouched();
    }
    return this.valid;
  }

  destroy() {
    Object.values(this.controls).forEach(item => this.destroyForm(item));
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  moveUp(index: number) {
    if (index <= 0) return;
    moveItemInArray(this.getControls(), index, index - 1);
    this.updateValueAndValidity();
    this.updateControlsSource();
  }

  moveDown(index: number) {
    const controls = this.getControls();
    if (index >= controls.length - 1) return;
    moveItemInArray(controls, index, index + 1);
    this.updateValueAndValidity();
    this.updateControlsSource();
  }

  private destroyForm(data: AbstractControl) {
    if ((data instanceof AppFormGroup || data instanceof AppFormArray) && data.destroy && !data.destroy$.closed) {
      data.destroy();
    }
  }
}
