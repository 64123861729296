import {
  AnimationMetadata,
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

const animateTime = '500ms ease-out';

const positionAbsolute = style({ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' });
const outOfScreenLeftEnter = style({ left: '100%' });
const outOfScreenRigthEnter = style({ left: '-100%' });
const onScreen = style({ left: '0' });
const outOfScreenLeft = style({ transform: 'translate(100%)' });
const outOfScreenRigth = style({ transform: 'translate(-100%)' });

const transitionRightToLeft: AnimationMetadata[] = [
  query(':enter', positionAbsolute),
  query(':enter', outOfScreenLeftEnter),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [animate(animateTime, outOfScreenRigth)], { optional: true }),
    query(':enter', [animate(animateTime, onScreen)], { optional: true }),
    query('@*', animateChild(), { optional: true }),
  ]),
];

const transitionLeftToRight: AnimationMetadata[] = [
  query(':enter', positionAbsolute),
  query(':enter', outOfScreenRigthEnter),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [animate(animateTime, outOfScreenLeft)], { optional: true }),
    query(':enter', [animate(animateTime, onScreen)], { optional: true }),
    query('@*', animateChild(), { optional: true }),
  ]),
];

const isRightToLeftMove = (from: string, to: string) => {
  return typeof +from === 'number' && typeof +to === 'number' && +from < +to;
};

const isLeftToRightMove = (from: string, to: string) => {
  return typeof +from === 'number' && typeof +to === 'number' && +from > +to;
};

export const cardGelleyAnimation = trigger('cardGalleryAnimation', [
  transition(isRightToLeftMove, transitionRightToLeft),
  transition(isLeftToRightMove, transitionLeftToRight),
]);
