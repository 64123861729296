import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { AppConstants } from '@core/app-constants';

import {
  ApiAuthToken,
  ApiUserRequest,
  ApiCampaign,
  ApiCharacter,
  ApiUser,
  ApiAuthTokenRequest,
  ApiCampaignListItem,
  ApiCharacterListItem,
  ApiSpellListItem,
  ApiSpell,
  ApiMagicItemListItem,
  ApiMagicItem,
  ApiCharacterRequest,
  ApiLookupRace,
  ApiLookupClass,
  ApiLookupSpell,
  ApiLookupRacialTrait,
  ApiLookupClassFeature,
  ApiClassListItem,
  ApiCharacterView,
  ApiAchievement,
  ApiUserUpdate,
  ApiClass,
  ApiClassFeature,
  ApiRaceListItem,
  ApiRace,
  ApiCharacterEquipment,
  ApiCharacterDescriptions,
  ApiCharacterHealthPointsAndAbilitiesRequest,
  ApiCharacterSpellsRequest,
  ApiCharacterRaceRequest,
  ApiCharacterClassRequest,
  ApiCharacterConfigurationRequest,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  login(data: ApiAuthTokenRequest) {
    const form = new FormData();
    form.append('Email', data.Email);
    form.append('Password', data.Password);
    return this.http.post<ApiAuthToken>(`${AppConstants.API_URL}/auth/token`, form);
  }

  refreshToken(data: ApiAuthToken) {
    const options = { params: new HttpParams() };
    options.params = options.params.append('token', data.access_token);
    options.params = options.params.append('refreshToken', data.refresh_token);
    return this.http.post<ApiAuthToken>(`${AppConstants.API_URL}/auth/token/refresh`, null, options);
  }

  register(data: ApiUserRequest) {
    return this.http.post<ApiUser>(`${AppConstants.API_URL}/User`, data);
  }

  getUser(id: string) {
    return this.http.get<ApiUser>(`${AppConstants.API_URL}/User/${id}`);
  }

  getCurrentUser() {
    return this.http.get<ApiUser>(`${AppConstants.API_URL}/User/Current`);
  }

  updateCurrentUser(data: ApiUserUpdate) {
    return this.http.put<ApiUser>(`${AppConstants.API_URL}/User/Current`, data);
  }

  getAchievements() {
    return this.http.get<ApiAchievement[]>(`${AppConstants.API_URL}/Achievements/Current`);
  }

  getCharacters() {
    return this.http.get<ApiCharacterListItem[]>(`${AppConstants.API_URL}/Characters`);
  }

  getCharacter(id: string) {
    return this.http.get<ApiCharacter>(`${AppConstants.API_URL}/Character/${id}`);
  }

  getCharacterView(id: string) {
    return this.http.get<ApiCharacterView>(`${AppConstants.API_URL}/Character/${id}`);
  }

  addCharacter(data: ApiCharacterRequest) {
    return this.http.post<ApiCharacter>(`${AppConstants.API_URL}/Character`, data);
  }

  updateCharacterEquipment(data: ApiCharacterEquipment, characterId: string) {
    const url = `${AppConstants.API_URL}/Character/${characterId}/Equipment/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterDescriptions(data: ApiCharacterDescriptions, characterId: string) {
    const url = `${AppConstants.API_URL}/Character/${characterId}/Descriptions/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterHealthPointsAndAbilities(data: ApiCharacterHealthPointsAndAbilitiesRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/Character/${characterId}/HealthPointsAndAbilities/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterClasses(data: { Classes: ApiCharacterClassRequest[] }, characterId: string) {
    const url = `${AppConstants.API_URL}/Character/${characterId}/Classes/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterRace(data: { Race: ApiCharacterRaceRequest }, characterId: string) {
    const url = `${AppConstants.API_URL}/Character/${characterId}/Race/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterSpells(data: ApiCharacterSpellsRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/Character/${characterId}/Spells/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  updateCharacterConfiguration(data: ApiCharacterConfigurationRequest, characterId: string) {
    const url = `${AppConstants.API_URL}/Character/${characterId}/Configuration/`;
    return this.http.patch<ApiCharacter>(url, data);
  }

  getCharacterGeneratedRaces(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupRace[]>(`${AppConstants.API_URL}/Character/Generated/Races`, options);
  }

  getCharacterGeneratedClasses(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupClass[]>(`${AppConstants.API_URL}/Character/Generated/Classes`, options);
  }

  getCharacterGeneratedSpells(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupSpell[]>(`${AppConstants.API_URL}/Character/Generated/Spells`, options);
  }

  getCharacterGeneratedRacialTraits(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupRacialTrait[]>(`${AppConstants.API_URL}/Character/Generated/RacialTraits`, options);
  }

  getCharacterGeneratedClassFeatures(characterId?: Guid | null) {
    const options = { params: new HttpParams() };
    if (characterId) options.params = options.params.append('id', characterId);
    return this.http.get<ApiLookupClassFeature[]>(`${AppConstants.API_URL}/Character/Generated/ClassFeatures`, options);
  }

  getCampaigns() {
    return this.http.get<ApiCampaignListItem[]>(`${AppConstants.API_URL}/Campaigns`);
  }

  getCampaign(id: string) {
    return this.http.get<ApiCampaign>(`${AppConstants.API_URL}/Campaign/${id}`);
  }

  addCampaign(data: ApiCampaign) {
    return this.http.post<ApiCampaign>(`${AppConstants.API_URL}/Campaign`, data);
  }

  updateCampaign(data: ApiCampaign) {
    return this.http.put<ApiCampaign>(`${AppConstants.API_URL}/Campaign`, data);
  }

  getSpells() {
    return this.http.get<ApiSpellListItem[]>(`${AppConstants.API_URL}/Spells`);
  }

  getSpell(slug: string) {
    return this.http.get<ApiSpell>(`${AppConstants.API_URL}/Spell/${slug}`);
  }

  getMagicItems() {
    return this.http.get<ApiMagicItemListItem[]>(`${AppConstants.API_URL}/MagicItems`);
  }

  getMagicItem(slug: string) {
    return this.http.get<ApiMagicItem>(`${AppConstants.API_URL}/MagicItem/${slug}`);
  }

  getClasses() {
    return this.http.get<ApiClassListItem[]>(`${AppConstants.API_URL}/Classes`);
  }

  getClass(slug: string) {
    return this.http.get<ApiClass>(`${AppConstants.API_URL}/Class/${slug}`);
  }

  getClassFeature(slug: string) {
    return this.http.get<ApiClassFeature>(`${AppConstants.API_URL}/Class/Feature/${slug}`);
  }

  getRaces() {
    return this.http.get<ApiRaceListItem[]>(`${AppConstants.API_URL}/Races`);
  }

  getRace(slug: string) {
    return this.http.get<ApiRace>(`${AppConstants.API_URL}/Race/${slug}`);
  }
}
