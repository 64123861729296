<form [formGroup]="form"
  class="form"
  (ngSubmit)="register()">
  <div class="logo-container">
    <img class="logo-img"
      src="/assets/logo.webp"
      alt="D&D Campaign">
  </div>
  <app-input class="mb-3"
    placeholder="Почта для входа"
    type="email"
    [control]="form.get('Email')">
    <mat-icon class="me-2 app-order-n1">mail</mat-icon>
  </app-input>
  <app-input class="mb-3"
    placeholder="Пароль"
    [type]="passwordHidden ? 'password' : 'text'"
    [control]="form.get('Password')">
    <mat-icon class="app-pointer me-2 app-order-n1"
      (click)="passwordHidden = !passwordHidden">
      {{passwordHidden ? 'password' : 'visibility'}}
    </mat-icon>
  </app-input>
  <button mat-raised-button
    type="submit"
    color="accent"
    class="submit-button mt-4">
    Зарегестрироваться
  </button>

  <p class="my-3 app-text-center">Регистрируясь, я соглашаюсь с
    <a class="nav-link d-inline app-text-accent app-pointer app-text-italic"
      (click)="license()">
      условиями использования
    </a> сервиса
  </p>
  <a class="nav-link app-text-accent app-pointer app-text-accent"
    (click)="openLogin()">
    Я уже зарегестрировался
  </a>
</form>