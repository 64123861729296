import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';

import { ToastrService } from '@core/services';
import { AppConstants } from '@core/app-constants';

import {
  ApiAdminUserListItem,
  ApiAdminClassFeature,
  ApiAdminClassFeatureListItem,
  ApiAdminFeat,
  ApiAdminFeatListItem,
  ApiAdminRacialTrait,
  ApiAdminRacialTraitListItem,
  ApiAdminSpell,
  ApiAdminSpellListItem,
  ApiAdminMagicItemListItem,
  ApiAdminMagicItem,
  ApiAdminRaceListItem,
  ApiAdminRace,
  ApiAdminClassListItem,
  ApiAdminClass,
  ApiAdminBackgroundListItem,
  ApiAdminBackground,
  ApiAdminUser,
} from '../types';

@Injectable({
  providedIn: 'root',
})
export class ApiAdminService {
  constructor(private http: HttpClient, private toastr: ToastrService) {}

  getAdminUsers() {
    return this.http.get<ApiAdminUserListItem[]>(`${AppConstants.API_URL}/Admin/Users`);
  }

  getAdminUser(id: string) {
    return this.http.get<ApiAdminUser>(`${AppConstants.API_URL}/User/${id}`);
  }

  updateAdminUser(data: ApiAdminUser) {
    return this.http.put<ApiAdminUser>(`${AppConstants.API_URL}/Admin/User`, data);
  }

  getAdminSpells() {
    return this.http.get<ApiAdminSpellListItem[]>(`${AppConstants.API_URL}/Admin/Spells`);
  }

  getAdminSpell(id: string) {
    return this.http.get<ApiAdminSpell>(`${AppConstants.API_URL}/Admin/Spell/${id}`);
  }

  addAdminSpell(data: ApiAdminSpell) {
    return this.http
      .post<ApiAdminSpell>(`${AppConstants.API_URL}/Admin/Spell`, data)
      .pipe(tap(() => this.toastr.show('Заклиание добавлено')));
  }

  updateAdminSpell(data: ApiAdminSpell) {
    return this.http
      .put<ApiAdminSpell>(`${AppConstants.API_URL}/Admin/Spell`, data)
      .pipe(tap(() => this.toastr.show('Заклиание изменено')));
  }

  deleteAdminSpell(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/Admin/Spell/${id}`)
      .pipe(tap(() => this.toastr.show('Заклиание удалено')));
  }

  getAdminRacialTraits() {
    return this.http.get<ApiAdminRacialTraitListItem[]>(`${AppConstants.API_URL}/Admin/RacialTraits`);
  }

  getAdminRacialTrait(id: string) {
    return this.http.get<ApiAdminRacialTrait>(`${AppConstants.API_URL}/Admin/RacialTrait/${id}`);
  }

  addAdminRacialTrait(data: ApiAdminRacialTrait) {
    return this.http
      .post<ApiAdminRacialTrait>(`${AppConstants.API_URL}/Admin/RacialTrait`, data)
      .pipe(tap(() => this.toastr.show('Особенность добавлена')));
  }

  updateAdminRacialTrait(data: ApiAdminRacialTrait) {
    return this.http
      .put<ApiAdminRacialTrait>(`${AppConstants.API_URL}/Admin/RacialTrait`, data)
      .pipe(tap(() => this.toastr.show('Особенность изменена')));
  }

  deleteAdminRacialTrait(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/Admin/RacialTrait/${id}`)
      .pipe(tap(() => this.toastr.show('Особенность удалена')));
  }

  getAdminClassFeatures() {
    return this.http.get<ApiAdminClassFeatureListItem[]>(`${AppConstants.API_URL}/Admin/ClassFeatures`);
  }

  getAdminClassFeature(id: string) {
    return this.http.get<ApiAdminClassFeature>(`${AppConstants.API_URL}/Admin/ClassFeature/${id}`);
  }

  addAdminClassFeature(data: ApiAdminClassFeature) {
    return this.http
      .post<ApiAdminClassFeature>(`${AppConstants.API_URL}/Admin/ClassFeature`, data)
      .pipe(tap(() => this.toastr.show('Способность добавлена')));
  }

  updateAdminClassFeature(data: ApiAdminClassFeature) {
    return this.http
      .put<ApiAdminClassFeature>(`${AppConstants.API_URL}/Admin/ClassFeature`, data)
      .pipe(tap(() => this.toastr.show('Способность изменена')));
  }

  deleteAdminClassFeature(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/Admin/ClassFeature/${id}`)
      .pipe(tap(() => this.toastr.show('Способность удалена')));
  }

  getAdminFeats() {
    return this.http.get<ApiAdminFeatListItem[]>(`${AppConstants.API_URL}/Admin/Feats`);
  }

  getAdminFeat(id: string) {
    return this.http.get<ApiAdminFeat>(`${AppConstants.API_URL}/Admin/Feat/${id}`);
  }

  addAdminFeat(data: ApiAdminFeat) {
    return this.http
      .post<ApiAdminFeat>(`${AppConstants.API_URL}/Admin/Feat`, data)
      .pipe(tap(() => this.toastr.show('Черта добавлена')));
  }

  updateAdminFeat(data: ApiAdminFeat) {
    return this.http
      .put<ApiAdminFeat>(`${AppConstants.API_URL}/Admin/Feat`, data)
      .pipe(tap(() => this.toastr.show('Черта изменена')));
  }

  deleteAdminFeat(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/Admin/Feat/${id}`)
      .pipe(tap(() => this.toastr.show('Черта удалена')));
  }

  getAdminBackgrounds() {
    return this.http.get<ApiAdminBackgroundListItem[]>(`${AppConstants.API_URL}/Admin/Backgrounds`);
  }

  getAdminBackground(id: string) {
    return this.http.get<ApiAdminBackground>(`${AppConstants.API_URL}/Admin/Background/${id}`);
  }

  addAdminBackground(data: ApiAdminBackground) {
    return this.http
      .post<ApiAdminBackground>(`${AppConstants.API_URL}/Admin/Background`, data)
      .pipe(tap(() => this.toastr.show('Предыстория добавлена')));
  }

  updateAdminBackground(data: ApiAdminBackground) {
    return this.http
      .put<ApiAdminBackground>(`${AppConstants.API_URL}/Admin/Background`, data)
      .pipe(tap(() => this.toastr.show('Предыстория изменена')));
  }

  deleteAdminBackground(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/Admin/Background/${id}`)
      .pipe(tap(() => this.toastr.show('Предыстория удалена')));
  }

  getAdminMagicItems() {
    return this.http.get<ApiAdminMagicItemListItem[]>(`${AppConstants.API_URL}/Admin/MagicItems`);
  }

  getAdminMagicItem(id: string) {
    return this.http.get<ApiAdminMagicItem>(`${AppConstants.API_URL}/Admin/MagicItem/${id}`);
  }

  addAdminMagicItem(data: ApiAdminMagicItem) {
    return this.http
      .post<ApiAdminMagicItem>(`${AppConstants.API_URL}/Admin/MagicItem`, data)
      .pipe(tap(() => this.toastr.show('Магический предмет добавлен')));
  }

  updateAdminMagicItem(data: ApiAdminMagicItem) {
    return this.http
      .put<ApiAdminMagicItem>(`${AppConstants.API_URL}/Admin/MagicItem`, data)
      .pipe(tap(() => this.toastr.show('Магический предмет изменен')));
  }

  deleteAdminMagicItem(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/Admin/MagicItem/${id}`)
      .pipe(tap(() => this.toastr.show('Магический предмет удален')));
  }

  getAdminRaces() {
    return this.http.get<ApiAdminRaceListItem[]>(`${AppConstants.API_URL}/Admin/Races`);
  }

  getAdminRace(id: string) {
    return this.http.get<ApiAdminRace>(`${AppConstants.API_URL}/Admin/Race/${id}`);
  }

  addAdminRace(data: ApiAdminRace) {
    return this.http
      .post<ApiAdminRace>(`${AppConstants.API_URL}/Admin/Race`, data)
      .pipe(tap(() => this.toastr.show('Раса добавлена')));
  }

  updateAdminRace(data: ApiAdminRace) {
    return this.http
      .put<ApiAdminRace>(`${AppConstants.API_URL}/Admin/Race`, data)
      .pipe(tap(() => this.toastr.show('Раса изменена')));
  }

  deleteAdminRace(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/Admin/Race/${id}`)
      .pipe(tap(() => this.toastr.show('Раса удалена')));
  }

  getAdminClasses() {
    return this.http.get<ApiAdminClassListItem[]>(`${AppConstants.API_URL}/Admin/Classes`);
  }

  getAdminClass(id: string): Observable<ApiAdminClass> {
    return this.http.get<ApiAdminClass>(`${AppConstants.API_URL}/Admin/Class/${id}`);
  }

  addAdminClass(data: ApiAdminClass) {
    return this.http
      .post<ApiAdminClass>(`${AppConstants.API_URL}/Admin/Class`, data)
      .pipe(tap(() => this.toastr.show('Класс добавлен')));
  }

  updateAdminClass(data: ApiAdminClass) {
    return this.http
      .put<ApiAdminClass>(`${AppConstants.API_URL}/Admin/Class`, data)
      .pipe(tap(() => this.toastr.show('Класс изменен')));
  }

  deleteAdminClass(id: string) {
    return this.http
      .delete(`${AppConstants.API_URL}/Admin/Class/${id}`)
      .pipe(tap(() => this.toastr.show('Класс удален')));
  }
}
