<app-toolbar></app-toolbar>
<div class="app-pages-container">
  <app-about-banner></app-about-banner>
  <router-outlet></router-outlet>
</div>
@if(!(isMobile$ | async)) {
<button mat-fab
  class="app-pages-feedback-button"
  matTooltip="Обратная связь"
  (click)="openFeedback()">
  <mat-icon>chat</mat-icon>
</button>
}
<app-footer></app-footer>