import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { Router } from '@angular/router';
import { BehaviorSubject, map, takeUntil } from 'rxjs';

import { ApiService } from '@api/services';
import { ApiCharacterListItem } from '@api/types';

import { AppService, ScreenService } from '@core/services';
import { BaseTakeUntil } from '@core/helpers';

import { CardGalleryComponent } from '@modules/card-gallery';

import { CharacterCardComponent } from './character-card/character-card.component';

@Component({
  selector: 'app-character-list',
  templateUrl: './character-list.component.html',
  styleUrls: ['./character-list.component.scss'],
  imports: [CardGalleryComponent, CharacterCardComponent, AsyncPipe],
})
export class CharacterListComponent extends BaseTakeUntil {
  private charactersSource$ = new BehaviorSubject<ApiCharacterListItem[]>([]);
  characters$ = this.charactersSource$.asObservable();
  private setCharacters(value: ApiCharacterListItem[]) {
    this.charactersSource$.next(value);
  }

  cols$ = this.screen.isMobile$.pipe(map(data => (data ? 2 : 4)));

  constructor(
    private router: Router,
    private apiService: ApiService,
    private appService: AppService,
    private screen: ScreenService
  ) {
    super();
  }

  ngOnInit() {
    this.appService.user$.pipe(takeUntil(this.destroy$)).subscribe(() => this.loadCharacters());
  }

  private loadCharacters() {
    if (this.appService.getUser())
      this.apiService
        .getCharacters()
        .subscribe({ next: data => this.setCharacters(data), error: () => this.setCharacters([]) });
    else this.setCharacters([]);
  }

  openCharacter(data: ApiCharacterListItem) {
    this.router.navigate(data?.Id ? ['characters', data.Id] : ['characters/add']);
  }
}
