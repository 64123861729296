import { Pipe, PipeTransform } from '@angular/core';

import { GroupedSelectOption } from '@modules/select-grouped';
import { SelectOption } from '@modules/select';

const COMPARATOR = (item: string, value: string | null) =>
  item?.toLocaleLowerCase().includes((value || '').toLocaleLowerCase());

@Pipe({
  standalone: true,
  name: 'search',
})
export class SearchPipe<T extends SelectOption> implements PipeTransform {
  transform(data: T[], prop: keyof T, value: string, external?: boolean): T[] {
    return external ? data : (data || [])?.filter(item => COMPARATOR(item[prop] as string, value));
  }
}

@Pipe({
  standalone: true,
  name: 'searchGroups',
})
export class SearchGroupsPipe implements PipeTransform {
  transform(data: GroupedSelectOption[], prop: string, value: string | null): GroupedSelectOption[] {
    return data.filter(
      item =>
        COMPARATOR(item[prop as keyof GroupedSelectOption] as string, value) ||
        item.Options.some(item => COMPARATOR(item[prop as keyof SelectOption] as string, value))
    );
  }
}

@Pipe({
  standalone: true,
  name: 'searchGroupOptions',
})
export class SearchGroupOptionsPipe implements PipeTransform {
  transform(data: GroupedSelectOption, prop: string, value: string | null): SelectOption[] {
    return COMPARATOR(data.Name, value)
      ? data.Options
      : data.Options.filter(item => COMPARATOR(item[prop as keyof SelectOption] as string, value));
  }
}
