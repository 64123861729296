import { FormControl } from '@angular/forms';

import { AppFormGroup, AppValidators } from '@core/helpers';

export interface LoginFormValue {
  Email: string;
  Password: string;
}

export class LoginForm extends AppFormGroup<LoginFormValue> {
  constructor() {
    super({
      Email: new FormControl(null, [AppValidators.required, AppValidators.email]),
      Password: new FormControl(null, AppValidators.required),
    });
  }
}
