import { Component, Inject, Optional } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

import { ApiService } from '@api/services/api.service';
import { ApiHttpErrorResponse } from '@api/types';

import { ToastrService } from '@core/services/toastr.service';

import { AuthService } from '@modules/auth';
import { InputComponent } from '@modules/input';
import { ModalService } from '@modules/modal';

import { LoginModalComponent } from '../login-modal/login-modal.component';
import { LicenseAgreementComponent } from '../../docs/license-agreement/license-agreement.component';
import { RegisterForm } from './register-modal.form';

export interface RegisterModalData {
  successRoute?: string;
}

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss'],
  host: { class: 'app-auth-modal' },
  imports: [MatButtonModule, ReactiveFormsModule, RouterModule, MatIconModule, InputComponent],
})
export class RegisterModalComponent {
  form = new RegisterForm();

  protected passwordHidden = true;

  private data?: RegisterModalData;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) matDialogData: RegisterModalData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) matBottomSheetData: RegisterModalData,
    private router: Router,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private apiService: ApiService,
    private authService: AuthService,
    private modal: ModalService
  ) {
    if (matDialogData) this.data = matDialogData;
    if (matBottomSheetData) this.data = matBottomSheetData;
  }

  register() {
    if (!this.form.validate()) return;
    this.apiService.register(this.form.getRawValue()).subscribe({
      next: () => {
        this.apiService.login(this.form.getRawValue()).subscribe({
          next: data => {
            this.modal.close();
            this.authService.authenticate(data);
            if (this.data?.successRoute) this.router.navigate([this.data.successRoute]);
            else if (this.authService.getRole() === 'Admin') this.router.navigate(['/admin']);
            else this.router.navigate(['/profile']);
          },
          error: this.form.errorHandler,
        });
      },
      error: (error: ApiHttpErrorResponse) => {
        this.form.errorHandler(error);
        if (error.error.Value.ModelState) {
          const message = Object.values(error.error.Value.ModelState)
            .reduce((res, val) => [...res, ...val], [])
            .join('\n');
          this.toastr.show(message, { duration: 5000 });
        }
      },
    });
  }

  license() {
    this.dialog.open(LicenseAgreementComponent, {
      width: 'auto',
      maxWidth: '1000px',
      maxHeight: '80vh',
      hasBackdrop: true,
      panelClass: 'app-dialog',
      backdropClass: 'app-overlay-backdrop',
      autoFocus: false,
    });
  }

  openLogin() {
    this.modal.open(LoginModalComponent, { width: 'auto' });
  }
}
