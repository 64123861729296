import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  standalone: true,
  name: 'errorTooltip',
})
export class ErrorTooltipPipe implements PipeTransform {
  transform(data: ValidationErrors | null | undefined) {
    return Object.entries(data || {})
      .map(([key, value]) => {
        switch (key) {
          case 'required':
            return 'Required';
          case 'pattern':
            return null;
          case 'min':
            return `Minimum value is ${value.min}`;
          case 'max':
            return `Maximum value is ${value.max}`;
          case 'maxLength':
            return `Maximum length is ${value.requiredLength}`;
          case 'minLength':
            return `Minimum length is ${value.requiredLength}`;
          case 'minLengthArray':
            return `Min items: ${value}`;
          case 'notAllowed':
            return 'Not allowed';
          case 'defaultRequired':
            return 'Default Required';
          case 'maxNumber':
            return value;
          default:
            return value;
        }
      })
      .join('\n');
  }
}
