@if(youtube) {
<iframe width="100%"
  height="100%"
  src="https://www.youtube.com/embed/4I0_vty2w1Y?si=w1-fkFhwOQCHnWRS"
  title="D&D Campaign приветственное видео от разработчиков"
  frameborder="0"
  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
  referrerpolicy="strict-origin-when-cross-origin"
  allowfullscreen>
</iframe>
}
@else {
<video #video
  class="video"
  width="100%"
  height="100%"
  preload="none"
  poster="assets/about/video/poster.webp">
  <source src="assets/about/video/greetings.mp4"
    type="video/mp4">
  Your browser does not support the video tag.
</video>
<div class="video-controls"
  [class.visible]="!isPlaying"
  (click)="!isPlaying ? play(): pause()">
  @if (!isPlaying) {
  <img class="video-play-control"
    src="assets/interface-icons/play_circle.svg"
    alt="Play"
    draggable="false">
  }
  @if (isPlaying) {
  <img class="video-pause-control"
    src="assets/interface-icons/pause_circle.svg"
    alt="Pause"
    draggable="false">
  }
</div>
}