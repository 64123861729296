import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SeoService } from '@core/services';

@Component({
  selector: 'app-content-license',
  templateUrl: './content-license.component.html',
  host: { class: 'app-page' },
  imports: [RouterModule],
})
export class ContentLicenseComponent {
  constructor(private seoService: SeoService) {
    this.seoService.set({
      title: 'Лицензия на использование контента - D&D Campaign',
      description: 'Вы можете использовать контент имеющий ссылку на эту страницу на условиях лицензии CC BY-NC-SA 4.0',
    });
  }
}
